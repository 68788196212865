import React, { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import { Dialog, DialogContent, Button, Grid, Container, Box } from "@mui/material";
import Provider from "./Provider";
import HowCreateImage from "./HowCreateImage";
import Prompt from "./Prompt";

const AiSettingsPopup = observer(({ open, onClose }) => {
    const LOCAL_STORAGE_KEY = "aiSettingsSelectedMenu";

    const [selectedMenu, setSelectedMenu] = useState(() => {
        return localStorage.getItem(LOCAL_STORAGE_KEY) || "provider";
    });

    useEffect(() => {
        localStorage.setItem(LOCAL_STORAGE_KEY, selectedMenu);
    }, [selectedMenu]);

    // ________________________________________________________
    // קוד שמאזין לשינוי בגובה של ה-Dialog ומעדכן את הגובה שלו
    const [height, setHeight] = useState(0);
    const elementRef = useRef(null);

    useEffect(() => {
        if (!open) return;

        setTimeout(() => {
            const element = elementRef.current;
            if (!element) return;

            const resizeObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    setHeight(entry.contentRect.height);
                }
            });

            resizeObserver.observe(element);

            return () => {
                resizeObserver.disconnect();
            };
        }, 100); // עיכוב קצר שמאפשר ל-Dialog להיטען קודם
    }, [open]);

    // ________________________________________________________
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth={false} dir="ltr"
            PaperProps={{ ref: elementRef, style: { width: '2000px', height: '1000px', maxWidth: 'none' } }}>
            <DialogContent sx={{ p: 0, m: 0, overflow: "hidden" }}>
                <Container dir="rtl" disableGutters maxWidth={false} sx={{ p: 1 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={1} sx={{ pt: "0 !important" }}>
                            {/* <p>Height: {height}px</p> */}
                            <Button fullWidth onClick={() => setSelectedMenu("provider")}
                                variant={selectedMenu === "provider" ? "contained" : "outlined"} sx={{ mt: 1 }}>
                                ספק
                            </Button>
                            <Button fullWidth onClick={() => setSelectedMenu("prompt")}
                                variant={selectedMenu === "prompt" ? "contained" : "outlined"} sx={{ mt: 0.5 }}>
                                פרומפט
                            </Button>
                            {/* <Button fullWidth onClick={() => setSelectedMenu("howCreateImage")}
                                variant={selectedMenu === "howCreateImage" ? "contained" : "outlined"} sx={{ mt: 1 }}>
                                אופן יצירת התמונה
                            </Button> */}
                        </Grid>

                        <Grid item xs={11}>
                            {selectedMenu === "provider" && <Provider />}
                            {selectedMenu === "howCreateImage" && <HowCreateImage />}
                            {selectedMenu === "prompt" && <Prompt height={height} />}
                        </Grid>
                    </Grid>
                </Container>
            </DialogContent>

            <Box sx={{ position: "absolute", top: 6, right: 6 }}>
                <Button onClick={onClose} color="primary" variant="contained">סגור</Button>
            </Box>
        </Dialog>
    );
});

const AiSettings = () => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <Button variant="contained" onClick={() => setOpen(true)}>
                הגדרות AI
            </Button>
            <AiSettingsPopup open={open} onClose={() => setOpen(false)} />
        </>
    );
};

export default AiSettings;