import React from 'react';
import Layout from '../Layout/Layout';

// MUI
import { Grid, Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

// Sections
import DictionaryList from './Dictionary/DictionaryList';
import ExistsInfo from './ExistsInfo/ExistsInfo';
import NewInfo from './NewInfo/NewInfo';

// Tools
import DictionaryTypeSelector from '../Dictionary/Tools/DictionaryTypeSelector';
import NumberOfResults from '../Dictionary/Tools/NumberOfResults';
import SearchForm from '../Dictionary/Tools/SearchForm';
import FilterDictionaryByAdditionalInfo from './Tools/FilterDictionaryByAdditionalInfo';
import FilterDictionaryByRange from './Tools/FilterDictionaryByRange';
import SortFieldSelector from '../Dictionary/Tools/SortFieldSelector';
import KeyboardShortcuts from '../Dictionary/Tools/KeyboardShortcuts';
import ReloadingRamMemory from '../Dictionary/Tools/ReloadingRamMemory';
import AiSettings from './Settings/AiSettings';
import DateTimeRangePicker from './Tools/DateTimeRangePicker';
import AutoCreation from './Tools/AutoCreation';

// Mobx
import { observer } from "mobx-react-lite";
import dictionary from '../../Mobx/Dictionary';
import additionalInfo from '../../Mobx/AdditionalInfo';

const AIPage = observer(() => {
    const isDisplay = dictionary.current.value.index !== -1;
    const loading = additionalInfo.loading.getExistsInfo;

    const tools = [
        <AutoCreation />,
        <DictionaryTypeSelector />,
        <NumberOfResults />,
        <SearchForm />,
        <AiSettings />,
    ];
    const drawerTools = [
        <FilterDictionaryByAdditionalInfo />,
        <FilterDictionaryByRange />,
        <DateTimeRangePicker />,
        <SortFieldSelector />,
        <ReloadingRamMemory />,
        <KeyboardShortcuts />
    ];
    const headerHeight = 100;

    return (
        <Layout tools={tools} drawerTools={drawerTools}>
            <Grid
                container
                spacing={0}
                style={{ height: `calc(100vh - ${headerHeight}px)` }}
            >
                <Grid item xs={3}>
                    <Box
                        sx={{
                            height: '100%',
                            borderTop: '0px solid rgb(175, 208, 255)',
                            borderLeft: '4px solid rgb(175, 208, 255)',
                            borderRight: '2px solid rgb(175, 208, 255)',
                            borderBottom: '4px solid rgb(175, 208, 255)',
                            boxSizing: 'border-box',
                        }}
                    >
                        <DictionaryList />
                    </Box>
                </Grid>
                <Grid item xs={4.5}>
                    <Box
                        sx={{
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderTop: '0px solid rgb(175, 208, 255)',
                            borderLeft: '2px solid rgb(175, 208, 255)',
                            borderRight: '2px solid rgb(175, 208, 255)',
                            borderBottom: '4px solid rgb(175, 208, 255)',
                            boxSizing: 'border-box',
                        }}
                    >
                        {
                            loading ? <CircularProgress /> :
                                (isDisplay && <ExistsInfo />)
                        }
                    </Box>
                </Grid>
                <Grid item xs={4.5}>
                    <Box
                        sx={{
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderTop: '0px solid rgb(175, 208, 255)',
                            borderLeft: '2px solid rgb(175, 208, 255)',
                            borderRight: '4px solid rgb(175, 208, 255)',
                            borderBottom: '4px solid rgb(175, 208, 255)',
                            boxSizing: 'border-box',
                        }}
                    >
                        {
                            loading ? <CircularProgress /> :
                                (isDisplay && <NewInfo />)
                        }
                    </Box>
                </Grid>
            </Grid>
        </Layout>
    );
});

export default AIPage;
