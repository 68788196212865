import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { AutoSizer, List } from 'react-virtualized';
import dictionary from '../../../Mobx/Dictionary';
import DictionaryCard from './DictionaryCard';

const DictionaryList = observer(() => {
    // _________________________________________________________________________________________
    const [data, setData] = useState(dictionary.current.data[dictionary.current.dictionaryType]);
    const currentIndex = dictionary.current.value.index;

    const rowRenderer = ({ key, index, style }) => {
        const item = data[index];
        const isSelected = index === currentIndex;

        return (
            <div key={key} style={style} className="list-item">
                <DictionaryCard
                    data={item}
                    isSelected={isSelected} />
            </div>
        );
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            setData(dictionary.current.data[dictionary.current.dictionaryType]);
        }, 100);
    
        return () => clearTimeout(timeout); // מבטל אם משתנה שוב מהר
    }, [
        dictionary.search.text, dictionary.search.field, dictionary.search.includes,
        dictionary.current.sort.field, dictionary.current.sort.order, 
        dictionary.current.dictionaryType, dictionary.dataChanged
    ]);

    // _________________________________________________________________________________________
    return (
        <AutoSizer
            key={ // This is to force the list to re-render when the rowHeight changes or the dictionary type changes
                dictionary.current.dictionaryType +
                dictionary.current.sort?.field +
                dictionary.current.sort?.order +
                dictionary.dataChanged
            }
        >
            {({ width, height }) => (
                <List
                    width={width - 5}
                    height={height - 6}
                    rowCount={data.length}
                    rowHeight={125}
                    rowRenderer={rowRenderer}
                    scrollToIndex={currentIndex}
                    scrollToAlignment="center"
                />
            )}
        </AutoSizer>
    );
});

export default DictionaryList;
