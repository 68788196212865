import React from 'react';
import { observer } from 'mobx-react';
import { Card, CardContent, Typography, Grid, Avatar, CardHeader } from '@mui/material';
import customers from '../../Mobx/Customers';

const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('he-IL', {
        year: 'numeric',
        month: '2-digit',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    });
};

const CustomerCard = observer(({ customer }) => {
    const { customer_fields } = customers; // שליפת השדות מתוך ה-Store

    return (
        <Card
            sx={{
                width: 300, // רוחב קבוע
                height: 350, // גובה קבוע
                margin: '4px',
                backgroundColor: '#f5f5f5',
                display: 'flex', // להבטיח סידור תוכן
                flexDirection: 'column', // סידור לאורך
            }}
        >
            <CardHeader
                sx={{
                    backgroundColor: '#343a40', // רקע כהה
                    color: '#fff', // צבע טקסט לבן
                    padding: '16px',
                    borderBottom: '1px solid #e0e0e0', // גבול תחתון
                }}
                avatar={
                    <Avatar
                        src={customer.profilePicture || '/default-avatar.png'} // תמונת פרופיל
                        alt={`${customer.firstName} ${customer.lastName}`}
                        sx={{
                            width: 56,
                            height: 56,
                            border: '2px solid #ffffff', // מסגרת מסביב לאווטר
                            boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.3)', // צל מסביב לאווטר
                            marginLeft: '16px', // רווח בין התמונה לשם
                        }}
                    />
                }
                title={
                    <Typography
                        variant="h6"
                        sx={{
                            fontWeight: 'bold',
                            color: '#ffcc00' // שינוי צבע הטקסט של הכותרת
                        }}
                    >
                        {`${customer.firstName} ${customer.lastName}`}
                    </Typography>
                }
                subheader={
                    <Typography
                        variant="body2"
                        sx={{
                            color: '#ccc', // צבע טקסט משני
                        }}
                    >
                        {customer.role || 'Customer'}
                    </Typography>
                }
            />
            <CardContent>
                <Grid container spacing={1}>
                    {Object.entries(customer).map(([field, value]) => {
                        // דילוג על השדות profilePicture, firstName, ו-lastName
                        if (['profilePicture', 'firstName', 'lastName', 'role'].includes(field)) {
                            return null;
                        }

                        // הצגת השדה רק אם הוא קיים במערך customer_fields
                        if (!customer_fields.includes(field)) {
                            return null;
                        }

                        // פורמט מיוחד לתאריך
                        if (field === 'createdAt' && value) {
                            value = formatDate(value);
                        }

                        return (
                            <Grid item xs={12} key={field}>
                                <Typography variant="body2" color="text.secondary">
                                    <strong>{field}:</strong> {value}
                                </Typography>
                            </Grid>
                        );
                    })}
                </Grid>
            </CardContent>
        </Card>
    );
});

export default CustomerCard;
