import React, { useState, useEffect } from 'react';
import dictionary from '../../../Mobx/Dictionary';
import { FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import './SortFieldSelector.css';

const SortFieldSelector = () => {
    const [sortField, setSortField] = useState(dictionary.current.sort?.field);
    const [sortOrder, setSortOrder] = useState(dictionary.current.sort?.order);
    const [options, setOptions] = useState([]);

    // Update options based on dictionary type
    useEffect(() => {
        const dictionaryType = dictionary.current.dictionaryType;
        if (dictionaryType === 'dictionary_eng_to_heb') {
            setOptions(dictionary.dictionary_eng_to_heb_fields);
        } else if (dictionaryType === 'dictionary_heb_to_eng') {
            setOptions(dictionary.dictionary_heb_to_eng_fields);
        }
    }, [dictionary.current.dictionaryType]);

    const handleSortChange = (event) => {
        const selectedSortField = event.target.value;
        setSortField(selectedSortField);
        dictionary.setCurrent(null, null, null, null, selectedSortField, sortOrder); // מעביר את sortOrder
    };

    const handleSortOrderChange = (event) => {
        const selectedSortOrder = event.target.value;
        setSortOrder(selectedSortOrder);
        dictionary.setCurrent(null, null, null, null, sortField, selectedSortOrder); // מעביר את sortField
    };

    return (
        <div className="SortFieldSelector">
            <Typography variant="h7">
                מיון המילון
            </Typography>
            <div className="form-controls">
                <FormControl fullWidth>
                    <InputLabel id="sortField-label">מיין לפי</InputLabel>
                    <Select
                        labelId="sortField-label"
                        id="sortField"
                        variant='standard'
                        value={sortField === 'originalWithoutPunctuation' ? 'original' : sortField}
                        onChange={handleSortChange}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    direction: 'ltr', // גורם לרשימה להיות משמאל לימין
                                },
                            },
                        }}
                    >
                        {options.map((field) => (
                            <MenuItem key={field} value={field}>
                                {field}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl fullWidth>
                    <InputLabel id="sortOrder-label">סדר</InputLabel>
                    <Select
                        labelId="sortOrder-label"
                        id="sortOrder"
                        variant='standard'
                        value={sortOrder}
                        onChange={handleSortOrderChange}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    direction: 'ltr', // גורם לרשימה להיות משמאל לימין
                                },
                            },
                        }}
                    >
                        <MenuItem value="asc">עולה</MenuItem>
                        <MenuItem value="desc">יורד</MenuItem>
                    </Select>
                </FormControl>
            </div>
        </div>
    );
};

export default SortFieldSelector;
