import React, { useLayoutEffect, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import CustomersList from './CustomersList';
import Layout from '../Layout/Layout';
import customers from '../../Mobx/Customers';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const CustomerPage = observer(() => {

    useLayoutEffect(() => {
        // dictionary.setCurrent(null, { text: '', field: 'all' }); // -1 To prevent slow loading of the UI.
    }, []);

    useEffect(() => {
        customers.getCustomers();
    }, []);

    // _____________________________________________________________________
    const tools = [
    ];
    const drawerTools = [

    ];

    return (
        <div className="CustomerPage">
            <Layout tools={tools} drawerTools={drawerTools}>
                {customers.customers.length > 0 ? (
                    <CustomersList customers={customers.customers} />
                ) : (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', marginTop: '190px' }}>
                        <CircularProgress />
                    </Box>
                )}
            </Layout>
        </div>
    );
});

export default CustomerPage;
