import React, { useState } from 'react';
import { Button, TextField } from '@mui/material';
import groups from '../../Mobx/Groups';
import dictionary from '../../Mobx/Dictionary';

const CreateCategory = () => {
    const [category, setCategory] = useState({ name: '' });

    const handleChange = (e) => {
        const { value } = e.target;
        setCategory({ name: value });
        handleInputChange(e); // קריאה לפונקציה שמקבעת כיוון טקסט
    };

    const handleSubmit = async () => {
        try {
            if (category.name.trim() === '') return; // אם השדה ריק, לא להמשיך

            const newCategory = {
                dictionaryType: dictionary.current.dictionaryType,
                ...category
            };

            const message = await groups.createCategory(newCategory);
            // alert(message); // הודעה למשתמש על יצירת הקטגוריה
            setCategory({ name: '' }); // איפוס הטופס
        } catch (error) {
            console.error('Error creating category:', error);
            alert('Failed to create category. Please try again.');
        }
    };

    // _________________________________________________________
    // פונקציה שמקבעת כיוון טקסט
    const [direction, setDirection] = useState('ltr'); // ברירת מחדל

    const handleInputChange = (event) => {
        const value = event.target.value;
        if (value) {
            // קביעת כיוון אוטומטית לפי התו הראשון
            const firstChar = value.trim()[0];
            setDirection(/[\u0590-\u05FF]/.test(firstChar) ? 'ltr' : 'rtl');
        } else {
            setDirection('ltr'); // ברירת מחדל כשהשדה ריק
        }
    };

    // _________________________________________________________
    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <TextField
                // label="שם הקטגוריה"
                value={category.name}
                onChange={handleChange}
                autoComplete="off"
                size="small"
                placeholder='שם הקטגוריה'
                sx={{
                    '& input': {
                        direction: direction, // כיוון דינמי
                    },
                    '& .MuiInputBase-root': {
                        backgroundColor: 'white', // צבע הרקע
                    },
                    '& .MuiInputBase-input': {
                        color: 'black', // צבע הטקסט
                    },
                    '& .MuiInputLabel-root': {
                        color: 'gray', // צבע התווית
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                        color: 'black', // צבע התווית כשהשדה בפוקוס
                    },
                }}
            />
            <Button variant="contained" color="primary" onClick={handleSubmit}>
                צור
            </Button>
        </div>
    );
};

export default CreateCategory;
