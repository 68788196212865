import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Box, Typography } from "@mui/material";
import additionalInfo from "../../Mobx/AdditionalInfo";

const ImageDropzone = () => {
    const [uploading, setUploading] = useState(false);

    const onDrop = useCallback(async (acceptedFiles) => {
        if (acceptedFiles.length === 0) return;
        setUploading(true);
        const file = acceptedFiles[0];
        await additionalInfo.addExistsInfo(file, null, null, null);
        setUploading(false);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: "image/*"
    });

    return (
        <Box
            {...getRootProps()}
            sx={{
                border: "2px dashed #aaa",
                padding: 1,
                marginBottom: 2,
                textAlign: "center",
                cursor: "pointer",
                borderRadius: 2,
                bgcolor: isDragActive ? "action.hover" : "background.paper",
            }}
        >
            <input {...getInputProps()} />
            <Typography variant="body1">
                {uploading ? "מעלה את התמונה . . ." :
                    isDragActive ? "שחרר כאן את הקובץ..." : "גרור ושחרר תמונה כאן או לחץ לבחירה"
                }
            </Typography>
        </Box>
    );
};

export default ImageDropzone;
