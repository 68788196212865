import axios from "axios";
import urls from "./Urls";
import { action, makeObservable, observable, runInAction } from "mobx";
import dictionary from "./Dictionary";
import { replacePlaceholders } from "../Utils/additionalInfo";

class AdditionalInfo {
    existsInfo = null;
    newInfo = null;
    expandedState = {}; // for Accordion state (images, sentences, definitions, stories)
    loading = {
        create: { sentence: false, definition: false, imageDescription: false, image: false, story: false },
        getExistsInfo: false, addExistsInfo: {}, updateExistsInfo: {}, deleteExistsInfo: {},
        aiSettings: false
    };
    snackbar = { open: false, message: "", severity: "error" };
    aiSettings = null;

    // Constructor _____________________________________________________________
    constructor() {
        makeObservable(this, {
            existsInfo: observable,
            newInfo: observable,

            expandedState: observable,
            loading: observable,
            snackbar: observable,

            setExpandedState: action,

            setSnackbar: action,
            clearSnackbar: action,

            getExistsInfo: action,
            addExistsInfo: action,
            updateExistsInfo: action,
            deleteExistsInfo: action,

            createWithAi: action,

            // AI Settings
            aiSettings: observable,
            getAiSettings: action,

            setSelectedProvider: action,
            setSelectedPrompt: action,

            createPrompt: action,
            updatePrompt: action,
            deletePrompt: action
        });

        this.clearExistsInfo();
        this.clearNewInfo();
        this.expandedState = this.getInitialExpandedState();

        // Bind the clearSnackbar method to ensure it retains the correct "this" context when called.
        this.clearSnackbar = this.clearSnackbar.bind(this);
    }

    // Loading _____________________________________________________________
    setLoading_NewInfo(field, value) {
        this.loading.create[field] = value;
    }

    setLoading_getExistsInfo(value) {
        this.loading.getExistsInfo = value;
    }

    setLoading_addExistsInfo(field, index, value) {
        if (!this.loading.addExistsInfo[field]) this.loading.addExistsInfo[field] = {};
        this.loading.addExistsInfo[field][index] = value;
    }

    setLoading_updateExistsInfo(field, nested_id, value) {
        if (!this.loading.updateExistsInfo[field]) this.loading.updateExistsInfo[field] = {};
        this.loading.updateExistsInfo[field][nested_id] = value;
    }

    setLoading_deleteExistsInfo(field, nested_id, value) {
        if (!this.loading.deleteExistsInfo[field]) this.loading.deleteExistsInfo[field] = {};
        this.loading.deleteExistsInfo[field][nested_id] = value;
    }

    // Accordion _____________________________________________________________
    getInitialExpandedState() {
        const savedState = localStorage.getItem("accordionState");
        return savedState ? JSON.parse(savedState) : { images: false, sentences: false, definitions: false, stories: false };
    }

    // Update the Accordion state and save it to localStorage
    setExpandedState(newState) {
        this.expandedState = newState;
        localStorage.setItem("accordionState", JSON.stringify(newState));
    }

    // Snackbar _____________________________________________________________
    setSnackbar(message, severity = "error") {
        this.snackbar = { open: true, message, severity };
    }

    clearSnackbar() {
        this.snackbar = { open: false, message: "", severity: "" };
    }

    // Exists Info _____________________________________________________________
    async getExistsInfo(additionalInfoId) {
        try {
            this.setLoading_getExistsInfo(true);

            if (!additionalInfoId) {
                this.clearExistsInfo();
                this.clearNewInfo();
                return;
            }

            const response = await axios.get(`${urls.additionalInfo.get}${additionalInfoId}`, {
                withCredentials: true
            });

            runInAction(() => {
                this.existsInfo = response.data;
                this.clearNewInfo();
            });
        } catch (error) {
            // this.setSnackbar(`Error getting additional information - ${error} - ${error?.response?.data}`, "error");
        } finally {
            this.setLoading_getExistsInfo(false);
        }
    }

    async addExistsInfo(image, sentence, definition, story, field, index) {
        try {
            if (field) this.setLoading_addExistsInfo(field, index, true);

            const wordId = dictionary.current.value._id;
            const dictionaryType = dictionary.current.dictionaryType;

            // Image is a URL
            let imageUrl = null;
            if (typeof image === "string" && image.startsWith("http")) {
                imageUrl = image;
            }

            // New additional info
            let newAdditionalInfo = {
                word: { dictionaryType, _id: wordId },
                images: { fileName: imageUrl },
                sentences: { text: sentence },
                definitions: { text: definition },
                stories: { text: story }
            }

            if (!imageUrl) delete newAdditionalInfo.images;
            if (!sentence) delete newAdditionalInfo.sentences;
            if (!definition) delete newAdditionalInfo.definitions;
            if (!story) delete newAdditionalInfo.stories;

            let response;
            // Image is a file
            if (image instanceof File) {
                const formData = new FormData();
                formData.append("image", image);
                formData.append("word", JSON.stringify({ dictionaryType, _id: wordId }));

                response = await axios.patch(urls.additionalInfo.add, formData, {
                    headers: { "Content-Type": "multipart/form-data" },
                    withCredentials: true
                });
            } else {
                response = await axios.patch(urls.additionalInfo.add, newAdditionalInfo, {
                    withCredentials: true
                });
            }

            runInAction(() => {
                const updatedAdditionalInfo = response.data.updatedAdditionalInfo;
                this.existsInfo = updatedAdditionalInfo;
                if (field) this.deleteNewInfo(field.slice(0, -1), index);

                // Update the dictionary with the new additional info
                dictionary.updateAdditionalInfo(updatedAdditionalInfo);
            });
        } catch (error) {
            this.setSnackbar(`Error adding additional information - ${error} - ${error?.response?.data}`, "error");
        } finally {
            if (field) this.setLoading_addExistsInfo(field, index, false);
        }
    }

    async updateExistsInfo(_id, field, nested_id, updateData) {
        try {
            // Set the loading state to the key of the first object in updateData
            this.setLoading_updateExistsInfo(field, nested_id, Object.keys(updateData)[0]); // e.g. "text", "default"

            const response = await axios.patch(urls.additionalInfo.update,
                { _id, field, nested_id, updateData },
                { withCredentials: true }
            );
            runInAction(() => {
                this.existsInfo = response.data;
            });
        } catch (error) {
            this.setSnackbar(`Error updating additional information - ${error} - ${error?.response?.data}`, "error");
        } finally {
            this.setLoading_updateExistsInfo(field, nested_id, false);
        }
    }

    async deleteExistsInfo(_id, field, nested_id) {
        try {
            this.setLoading_deleteExistsInfo(field, nested_id, true);

            const dictionaryType = dictionary.current.dictionaryType;
            const response = await axios.delete(`${urls.additionalInfo.delete}${dictionaryType}/${_id}/${field}/${nested_id}`, {
                withCredentials: true
            });
            runInAction(() => {
                const updatedAdditionalInfo = response.data;
                this.existsInfo = updatedAdditionalInfo;

                // Update the dictionary with the new additional info
                if (updatedAdditionalInfo.images.length === 0 &&
                    updatedAdditionalInfo.sentences.length === 0 &&
                    updatedAdditionalInfo.definitions.length === 0 &&
                    updatedAdditionalInfo.stories.length === 0
                ) {
                    dictionary.updateAdditionalInfo(null);
                } else {
                    dictionary.updateAdditionalInfo(updatedAdditionalInfo);
                }
            });
        } catch (error) {
            this.setSnackbar(`Error deleting additional information - ${error} - ${error?.response?.data}`, "error");
        } finally {
            this.setLoading_deleteExistsInfo(field, nested_id, false);
        }
    }

    // New Info _____________________________________________________________
    async createWithAi(type, prompt = null) {
        try {
            // loading
            if (this.loading.create[type]) return;
            this.setLoading_NewInfo(type, true);

            // currentWord
            const currentWord = dictionary.getOne(dictionary.current.value._id);

            // prompt
            if (!prompt) {
                // Get the selected prompt for the type
                // console.log(toJS(this.aiSettings.prompts.options[type]));
                prompt = this.aiSettings.prompts.options[type]
                    .find(prompt => prompt._id === this.aiSettings.prompts.selected[type]).prompt;

                // Replace placeholders in the prompt with values from the current word
                prompt = replacePlaceholders(prompt, currentWord);
            }

            // request
            const response = await axios.post(urls.ai.create, {
                type,
                prompt,
                provider: this.aiSettings.providers.selected,
                model: this.aiSettings.models[this.aiSettings.providers.selected].selected,
                temperature: this.aiSettings.temperature
            }, {
                withCredentials: true
            });
            const result = response.data.result;
            runInAction(() => {
                this.newInfo[type].push(result);
            });

            // snackbar
            const model = response.data.model;
            const elapsedTime = response.data.elapsedTime;
            if (type === "image") {
                this.setSnackbar(`התמונה נוצרה בהצלחה! מודל: ${model}, זמן: ${elapsedTime} שניות.`, "success");
            }
            else {
                let temperature = response.data.temperature;
                if (["o1", "o3-mini"].includes(model)) temperature = "אינו נתמך בדגם זה";
                const { input, output, total } = response.data.tokens;
                this.setSnackbar(`
                    הטקסט נוצר בהצלחה! מודל: ${model}, טמפרטורה: ${temperature}, זמן: ${elapsedTime} שניות.
                    טוקנים שאלה: ${input}, טוקנים תשובה: ${output}, סך הכל: ${total} טוקנים.
                   `, "success");
            }
        } catch (error) {
            this.setSnackbar(
                `Error creating ${type} - ${error} - ${error?.response?.data}`
                    .replace(/&quot;/g, '"').replace(/&amp;/g, '&')
                , "error");
        } finally {
            this.setLoading_NewInfo(type, false);
        }
    }

    deleteNewInfo(field, index) {
        if (field === "storie") field = "story";
        this.newInfo[field].splice(index, 1);
    }

    editNewInfo(field, index, value) {
        this.newInfo[field][index] = value;
    }

    // Clear _____________________________________________________________
    clearExistsInfo() {
        this.existsInfo = {
            _id: null,
            images: [
                // {
                //     fileName: null,
                //     _id: null
                // }
            ],
            sentences: [
                // {
                //     text: null,
                //     _id: null
                // }
            ],
            definitions: [
                // {
                //     text: null,
                //     _id: null
                // }
            ],
            stories: [
                // {
                //     text: null,
                //     _id: null
                // }
            ],
            createdAt: null
        };
    }
    clearNewInfo() {
        this.newInfo = {
            sentence: [],
            definition: [],
            imageDescription: [],
            image: [],
            story: []
        };
    }

    // AI Settings ___________________________________________________________
    async getAiSettings() {
        try {
            const response = await axios.get(urls.ai.getSettings, {
                withCredentials: true
            });
            runInAction(() => {
                this.aiSettings = response.data;
            });
        } catch (error) {
            // this.setSnackbar(`Error getting AI settings - ${error} - ${error?.response?.data}`, "error");
        }
    }

    async setSelectedProvider(provider) {
        try {
            this.loading.aiSettings = true;
            const response = await axios.patch(`${urls.ai.setSelectedProvider}${provider}`, {}, {
                withCredentials: true
            });
            runInAction(() => {
                this.aiSettings = response.data;
            });
        } catch (error) {
            this.setSnackbar(`Error setting selected provider - ${error} - ${error?.response?.data}`, "error");
        } finally {
            this.loading.aiSettings = false;
        }
    }

    async setSelectedModel(model) {
        try {
            this.loading.aiSettings = true;
            const provider = this.aiSettings.providers.selected;
            const response = await axios.patch(`${urls.ai.setSelectedModel}${provider}/${model}`, {}, {
                withCredentials: true
            });
            runInAction(() => {
                this.aiSettings = response.data;
            });
        } catch (error) {
            this.setSnackbar(`Error setting selected model - ${error} - ${error?.response?.data}`, "error");
        } finally {
            this.loading.aiSettings = false;
        }
    }

    async setTemperature(temperature) {
        try {
            this.loading.aiSettings = true;
            const response = await axios.patch(`${urls.ai.setTemperature}${temperature}`, {}, {
                withCredentials: true
            });
            runInAction(() => {
                this.aiSettings = response.data;
            });
        } catch (error) {
            this.setSnackbar(`Error setting temperature - ${error} - ${error?.response?.data}`, "error");
        } finally {
            this.loading.aiSettings = false;
        }
    }

    async setSelectedPrompt(type, promptId) {
        try {
            this.loading.aiSettings = true;
            const response = await axios.patch(`${urls.ai.setSelectedPrompt}${type}/${promptId}`, {}, {
                withCredentials: true
            });
            runInAction(() => {
                this.aiSettings = response.data;
            });
        } catch (error) {
            this.setSnackbar(`Error setting selected prompt - ${error} - ${error?.response?.data}`, "error");
        } finally {
            this.loading.aiSettings = false;
        }
    }

    async createPrompt(type, prompt, name) {
        try {
            this.loading.aiSettings = true;
            const response = await axios.post(urls.ai.createPrompt,
                { category: type, newPrompt: prompt, name },
                { withCredentials: true }
            );
            runInAction(() => {
                this.aiSettings = response.data;
            });
        } catch (error) {
            this.setSnackbar(`Error creating prompt - ${error} - ${error?.response?.data}`, "error");
        } finally {
            this.loading.aiSettings = false;
        }
    }

    async updatePrompt(type, promptId, prompt, name) {
        try {
            this.loading.aiSettings = true;
            const response = await axios.patch(urls.ai.updatePrompt,
                { category: type, promptId, newPrompt: prompt, name },
                { withCredentials: true }
            );
            runInAction(() => {
                this.aiSettings = response.data;
            });
        } catch (error) {
            this.setSnackbar(`Error updating prompt - ${error} - ${error?.response?.data}`, "error");
        } finally {
            this.loading.aiSettings = false;
        }
    }

    async deletePrompt(type, promptId) {
        try {
            this.loading.aiSettings = true;
            const response = await axios.delete(`${urls.ai.deletePrompt}${type}/${promptId}`, {
                withCredentials: true
            });
            runInAction(() => {
                this.aiSettings = response.data;
            });
        } catch (error) {
            this.setSnackbar(`Error deleting prompt - ${error} - ${error?.response?.data}`, "error");
        } finally {
            this.loading.aiSettings = false;
        }
    }
}

const additionalInfo = new AdditionalInfo();
export default additionalInfo;
