import React, { useState } from "react";
import { Tooltip } from "react-tooltip";
import { Box, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import InputPlaceholderGuide from "./InputPlaceholderGuide";

const InfoTooltip = () => {
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    return (
        <>
            {/* כפתור מידע שמפעיל את הטולטיפ */}
            <IconButton
                onClick={() => setIsTooltipOpen(!isTooltipOpen)}
                data-tooltip-id="info-tooltip"
                sx={{
                    // position: "fixed",
                    // top: "40px",
                    // right: "410px",
                    mt: 1.5,
                    zIndex: 9999, // לוודא שהכפתור מעל הכל
                    backgroundColor: isTooltipOpen ? "rgb(231, 231, 231)" : "transparent",
                }}
            >
                <Box display="flex" alignItems="center">
                    <InfoIcon sx={{ color: "#1976d2", marginRight: 0.5 }} />
                    <Typography variant="body2">קרא אותי</Typography>
                </Box>
            </IconButton>

            {/* טולטיפ */}
            <Tooltip
                id="info-tooltip"
                place="top"
                effect="solid"
                opacity='1'
                clickable={true}
                isOpen={isTooltipOpen}
                style={{
                    width: "750px", // גודל קבוע
                    backgroundColor: "rgb(231, 231, 231)",
                    color: "#333",
                    padding: "10px",
                    borderRadius: "8px",
                    textAlign: "right",
                    direction: "rtl",
                    boxShadow: "0px 2px 5px rgba(0,0,0,0.2)",
                    zIndex: 99999, // מוודא שהטולטיפ מעל הכל
                    position: "absolute",
                }}
            >
                <InputPlaceholderGuide />
            </Tooltip>
        </>
    );
};

export default InfoTooltip;