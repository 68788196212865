import React from "react";
import { observer } from "mobx-react-lite";
import { Snackbar, Alert } from "@mui/material";
import additionalInfo from "../../Mobx/AdditionalInfo";

const GlobalSnackbar = observer(() => {
    const { snackbar, clearSnackbar } = additionalInfo;

    return (
        <Snackbar
            open={snackbar.open}
            // autoHideDuration={snackbar.severity === "error" ? null : 6000}
            autoHideDuration={snackbar.severity === "error" ? null : null}
            onClose={clearSnackbar}
            anchorOrigin={
                snackbar.severity === "error" ?
                    { vertical: "bottom", horizontal: "center" } :
                    { vertical: "top", horizontal: "center" }
            }
        >
            <Alert onClose={clearSnackbar} severity={snackbar.severity} sx={{ width: "100%" }}>
                {snackbar.message}
            </Alert>
        </Snackbar>
    );
});

export default GlobalSnackbar;
