import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { ToggleButton, ToggleButtonGroup } from '@mui/lab'; // Import MUI ToggleButton
import dictionary from '../../../Mobx/Dictionary';

const DictionaryTypeSelector = observer(() => {
    const handleToggle = (event, newDictionaryType) => {
        if (newDictionaryType !== null) {
            dictionary.setCurrent(newDictionaryType, null); // Update the dictionary type
        }
    };

    // Handle Ctrl key press to toggle dictionary type
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.ctrlKey && event.altKey) {
                const currentType = dictionary.current.dictionaryType;
                const newType =
                    currentType === 'dictionary_heb_to_eng'
                        ? 'dictionary_eng_to_heb'
                        : 'dictionary_heb_to_eng';
                dictionary.setCurrent(newType, null); // Toggle dictionary type
            }
        };

        // Add keydown event listener
        window.addEventListener('keydown', handleKeyDown);

        // Clean up the event listener on unmount
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <ToggleButtonGroup
            sx={{
                maxHeight: '40px',
                borderRadius: '12px', // פינות מעוגלות
                backgroundColor: 'primary.light', // צבע רקע כללי
                // padding: '4px', // רווח פנימי
                boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.2)', // הצללה
            }}
            value={dictionary.current.dictionaryType}
            exclusive
            onChange={handleToggle}
        >
            <ToggleButton
                value="dictionary_heb_to_eng"
                sx={{
                    color: 'primary.contrastText', // צבע טקסט
                    fontSize: '16px', // גודל טקסט
                    fontWeight: 'bold', // עובי טקסט
                    borderRadius: '8px', // פינות מעוגלות
                    padding: '8px 16px', // רווח פנימי
                    '&.Mui-selected': {
                        backgroundColor: 'primary.dark', // רקע כאשר נבחר
                        color: 'primary.contrastText', // צבע טקסט כאשר נבחר
                    },
                    '&:hover': {
                        backgroundColor: 'primary.main', // רקע כשמרחפים
                    },
                }}
            >
                עברית
            </ToggleButton>
            <ToggleButton
                value="dictionary_eng_to_heb"
                sx={{
                    color: 'primary.contrastText',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    borderRadius: '8px',
                    padding: '8px 16px',
                    '&.Mui-selected': {
                        backgroundColor: 'primary.dark',
                        color: 'primary.contrastText',
                    },
                    '&:hover': {
                        backgroundColor: 'primary.main',
                    },
                }}
            >
                אנגלית
            </ToggleButton>
        </ToggleButtonGroup>
    );
});

export default DictionaryTypeSelector;
