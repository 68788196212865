class Urls {
    constructor(baseUrl) {
        this.baseUrl = baseUrl;

        this.user = {
            login: baseUrl + "users/login",
            logout: baseUrl + "users/logout",
            verifyToken: baseUrl + "users/verify-token",
            getOne: baseUrl + "users/user",
            getAll: baseUrl + "users",
            verifyCaptcha: baseUrl + "users/verify-captcha",
            loginWithGoogle: baseUrl + "users/auth/google", // Google login
            loginWithMinistryOfEducation: baseUrl + "users/auth/ministry-of-education", // Ministry of Education login
            logoutMinistryOfEducation: baseUrl + "users/logout-ministry-of-education", // Ministry of Education logout
        }

        this.dictionary = {
            dictionary_eng_to_heb: baseUrl + "dictionary/dictionary_eng_to_heb/",
            dictionary_heb_to_eng: baseUrl + "dictionary/dictionary_heb_to_eng/",
            reloading_ram_memory: baseUrl + "dictionary/ram-memory/reloading-dictionaries/",
        };

        this.categories = {
            category: baseUrl + "category/",
        };

        this.dictionaryLog = {
            getAll: baseUrl + "dictionary-log",
        };

        this.payment = {
            createSubscription_step1: baseUrl + "payment/paypal/subscription/create",
            createSubscription_step2: baseUrl + "payment/paypal/subscription/open",
            cancelSubscription: baseUrl + "payment/paypal/subscription/cancel",
        };

        this.additionalInfo = {
            get: baseUrl + "additional-info/get/",
            add: baseUrl + "additional-info/add",
            update: baseUrl + "additional-info/update",
            delete: baseUrl + "additional-info/",
        };

        this.file = {
            getImage: "https://lexillon-files.s3.us-east-1.amazonaws.com/files/images/",
        }

        this.ai = {
            create: baseUrl + "ai/create",
            getSettings: baseUrl + "ai/settings/get",
            setSelectedProvider: baseUrl + "ai/settings/set-selected-provider/",
            setSelectedModel: baseUrl + "ai/settings/set-selected-model/",
            setTemperature: baseUrl + "ai/settings/set-temperature/",
            setSelectedPrompt: baseUrl + "ai/settings/set-selected-prompt/",
            createPrompt: baseUrl + "ai/settings/create-prompt",
            updatePrompt: baseUrl + "ai/settings/update-prompt",
            deletePrompt: baseUrl + "ai/settings/delete-prompt/",
        }
    }
}

class DevelopmentUrls extends Urls {
    constructor() {
        super("http://localhost:3001/api/"); // Development server
    }
}

class ProductionUrls extends Urls {
    constructor() {
        super("https://test.lexillon.co.il/api/"); // Test server
        // super("https://main.lexillon.co.il/api/"); // Production server
    }
}

const urls = process.env.NODE_ENV === "development" ? new DevelopmentUrls() : new ProductionUrls();

export default urls;
