import React, { useState, useEffect, useLayoutEffect } from 'react';
import { observer } from 'mobx-react-lite';
import CreateDictionaryEntry from './DictionaryList/CreateDictionaryEntry';
import DictionaryList from './DictionaryList/DictionaryList';
import DictionaryStatus from './DictionaryLog/DictionaryStatus';
import Popup from '../SharedArea/Popup/Popup';
import dictionary from '../../Mobx/Dictionary';
import DictionaryLogList from './DictionaryLog/DictionaryLogList';
import Layout from '../Layout/Layout';
import Button from '@mui/material/Button';

// Tools
import DictionaryTypeSelector from './Tools/DictionaryTypeSelector';
import NumberOfResults from './Tools/NumberOfResults';
import SearchForm from './Tools/SearchForm';
import ColumnSelector from './Tools/ColumnSelector';
import ExportToExcel from './Tools/ExportToExcel';
import SortFieldSelector from './Tools/SortFieldSelector';
import KeyboardShortcuts from './Tools/KeyboardShortcuts';
import ReloadingRamMemory from './Tools/ReloadingRamMemory';

const DictionaryPage = observer(() => {
    // _____________________________________________________________________
    useLayoutEffect(() => {
        // dictionary.setCurrent(null, null); // -1 To prevent slow loading of the UI.
    }, []);

    // _____________________________________________________________________
    const [isPopupOpen_logs, setIsPopupOpen_logs] = useState(false);
    const openPopup_logs = () => setIsPopupOpen_logs(true);
    const closePopup_logs = () => setIsPopupOpen_logs(false);

    const [isPopupOpen_create, setIsPopupOpen_create] = useState(false);
    const openPopup_create = () => setIsPopupOpen_create(true);
    const closePopup_create = () => setIsPopupOpen_create(false);

    // _____________________________________________________________________
    let savedFields = localStorage.getItem('selectedFields');
    if (savedFields) { savedFields = JSON.parse(savedFields); }
    let defaultFields = savedFields || ['original', 'trns', 'createdAt', 'updatedAt'];

    const [selectedFields, setSelectedFields] = useState(
        dictionary.current.fields
            .filter(field => defaultFields.includes(field))
            .map(field => ({
                label: field.charAt(0).toUpperCase() + field.slice(1),
                value: field
            }))
    );

    useEffect(() => {
        localStorage.setItem('selectedFields', JSON.stringify(selectedFields.map(f => f.value)));
    }, [selectedFields]);

    // _____________________________________________________________________
    const tools = [
        <DictionaryTypeSelector />,
        <NumberOfResults />,
        <SearchForm />,
        <div>
            <span className="dictionary-status-span" onClick={openPopup_logs}>
                <DictionaryStatus />
            </span>
            <Popup
                isOpen={isPopupOpen_logs}
                onClose={closePopup_logs}
                title={'היסטוריית השינויים שבוצעו במילונים'}
            >
                <DictionaryLogList />
            </Popup>
        </div>,
        <div>
            <Button
                variant="contained"
                color="primary"
                onClick={openPopup_create}
            >
                צור ערך חדשה
            </Button>
            <Popup
                isOpen={isPopupOpen_create}
                onClose={closePopup_create}
                title={`יצירת ערך ${dictionary.current.dictionaryType === 'dictionary_eng_to_heb' ? 'באנגלית' : 'בעברית'}`}
            >
                <CreateDictionaryEntry />
            </Popup>
        </div>
    ];
    const drawerTools = [
        <ColumnSelector
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
        />,
        <SortFieldSelector />,
        <ExportToExcel />,
        <ReloadingRamMemory />,
        <KeyboardShortcuts />
    ];

    return (
        <div className="DictionaryPage">
            <Layout tools={tools} drawerTools={drawerTools}>
                <DictionaryList
                    selectedFields={selectedFields.map(f => f.value)}
                />
            </Layout>
        </div>
    );
});

export default DictionaryPage;
