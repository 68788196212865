import React from 'react';
import { observer } from "mobx-react-lite";
import { Typography } from '@mui/material';
import groups from '../../Mobx/Groups';
import dictionary from '../../Mobx/Dictionary';
import './CategoryCountDisplay.css';

const CategoryCountDisplay = observer(() => {
    const dictionaryType = dictionary.current.dictionaryType;
    const categories = groups.categories[dictionaryType];
    const totalCategories = categories.length;

    return (
        <Typography className="category-count-display" variant="h6">
            {totalCategories} קטגוריות
        </Typography>
    );
});

export default CategoryCountDisplay;
