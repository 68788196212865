import dictionary from "../Mobx/Dictionary";
import additionalInfo from "../Mobx/AdditionalInfo";
import { toJS } from "mobx";

// ______________________________________________________________________________________
// Filter the dictionary by the additional info
export function filterDictionaryByAdditionalInfo(dictionaryData, { condition, amount, field }) {
    console.log("condition, amount, field", condition, amount, field);
    // מיפוי שם השדה למיקום שלו במערך additionalInfoCount
    const fieldIndexMap = {
        images: 0,
        sentences: 1,
        definitions: 2,
        stories: 3
    };

    // בדיקה אם השדה תקין
    if (!(field in fieldIndexMap)) {
        throw new Error(`Invalid field: ${field}`);
    }

    const fieldIndex = fieldIndexMap[field];

    // ביצוע הסינון לפי התנאי
    return dictionaryData.filter(value => {
        const count = value.additionalInfoCount[fieldIndex];

        switch (condition) {
            case 'more':
                return count > amount;
            case 'less':
                return count < amount;
            case 'exactly':
                return count === amount;
            default:
                throw new Error(`Invalid condition: ${condition}`);
        }
    });
}

// Filter the dictionary by the date the info was updated
export function filterDictionaryByAdditionalInfoDate(dictionaryData, { from, to }) {
    // המרת המחרוזות לאובייקטים מסוג Date
    const fromDate = new Date(from);
    const toDate = new Date(to);

    // בדיקה אם תאריכים חוקיים
    if (isNaN(fromDate.getTime()) || isNaN(toDate.getTime())) {
        throw new Error("Invalid date format");
    }

    // סינון הערכים לפי טווח התאריכים
    return dictionaryData.filter(value => {
        const updatedAt = new Date(value.info_updatedAt);
        return updatedAt >= fromDate && updatedAt <= toDate;
    });
}

// ______________________________________________________________________________________
// Replace placeholders in a string with values from an object
export function replacePlaceholders(str, currentWord) {
    // ---------- Get the current word ----------
    if (!currentWord) return str;
    currentWord = toJS(currentWord); // Convert the MobX object to a plain object

    // Get the additional info for the current word
    let currentAdditionalInfo = additionalInfo.existsInfo;
    currentWord.sentence = currentAdditionalInfo.sentences[0]?.text;
    currentWord.definition = currentAdditionalInfo.definitions[0]?.text;

    // Get the category for the current word
    if (currentWord.categories && currentWord.categories.length > 0) {
        currentWord.category = currentWord.categories[0].name;
        delete currentWord.categories;
    }

    // ---------- Replace placeholders in the str ----------
    /* 
      Replaces `{key}content{/key}` with `content` if `key` exists in the object, 
      otherwise removes the entire structure, including the content.
    */
    str = str.replace(/\{([\w]+)\}([^{}]*)\{\/\1\}/g, (match, key, content) => {
        return currentWord[key] !== undefined ? content : ''; // Keeps content if the key exists, otherwise removes it.
    });

    /* 
      Replaces `-key-` with its corresponding value from the object if it exists, 
      otherwise removes the `-key-` pattern from the text.
    */
    return str.replace(/-([\w]+)-/g, (match, key) => currentWord[key] !== undefined ? currentWord[key] : match);
}

// ______________________________________________________________________________________
let isCanceled = false;

// type: "image", "sentence", "definition"
export async function createWithAiForMultipleValues(type, quantity, delayTime = 10000, withDescription) {
    isCanceled = false;
    await delay(delayTime); // delay

    for (const value of dictionary.current.data[dictionary.current.dictionaryType]) {
        if (isCanceled) break; // cancel

        // Set the current word ------------------------------------------------
        dictionary.setCurrent(null, null, value._id);
        await delay(delayTime > 2000 ? delayTime : 2000); // delay
        if (isCanceled) break; // cancel

        for (let i = 0; i < quantity; i++) {
            if (isCanceled) break; // cancel

            // Create the new info ----------------------------------------------
            switch (type) {
                case "image":
                    if (withDescription) {
                        await additionalInfo.createWithAi("imageDescription");
                        if (isCanceled) break; // cancel
                        const description = additionalInfo.newInfo.imageDescription?.[additionalInfo.newInfo.imageDescription.length - 1];
                        await additionalInfo.createWithAi(type, description);
                    }
                    else await additionalInfo.createWithAi(type);
                    break;
                case "sentence":
                case "definition":
                    await additionalInfo.createWithAi(type);
                    break;
                default:
                    break;
            }

            await delay(delayTime); // delay
            if (isCanceled) break; // cancel

            // Save the new info ------------------------------------------------
            if (additionalInfo.newInfo[type].length === 0) continue;
            let index = additionalInfo.newInfo[type].length - 1;
            let newInfo = additionalInfo.newInfo[type][index];
            switch (type) {
                case "image":
                    await additionalInfo.addExistsInfo(newInfo, null, null, null, "images", index);
                    break;
                case "sentence":
                    await additionalInfo.addExistsInfo(null, newInfo, null, null, "sentences", index)
                    break;
                case "definition":
                    await additionalInfo.addExistsInfo(null, null, newInfo, null, "definitions", index)
                    break;
                case "story":
                    await additionalInfo.addExistsInfo(null, null, null, newInfo, "stories", index)
                    break;
                default:
                    break;
            }
            // -------------------------------------------------------------------
        }
        await delay(delayTime); // delay
    }
}

export async function cancelCreation() {
    isCanceled = true;
}

function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
// ______________________________________________________________________________________
