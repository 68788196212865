import React, { useState } from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, InputLabel, Select, MenuItem, TextField, Box, Typography, LinearProgress } from "@mui/material";
import { createWithAiForMultipleValues, cancelCreation } from "../../../Utils/additionalInfo";
import dictionary from "../../../Mobx/Dictionary";
import { observer } from "mobx-react-lite";

const AutoCreation = observer(() => {
    // state ---------------------------------------------------------------
    const [open, setOpen] = useState(false);

    const [type, setType] = useState("sentence");
    const [quantity, setQuantity] = useState(1);
    const [delayTime, setDelayTime] = useState(1);

    const [isCanceled, setIsCanceled] = useState(true);

    // Progress Bar calculation
    let total = dictionary.current.data[dictionary.current.dictionaryType].length;
    let done = dictionary.current.value.index;
    done = done === -1 ? 0 : done;
    let remaining = total - done;
    let progress = Math.max(0, (remaining / total) * 100);

    // functions -----------------------------------------------------------
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleCreate = async () => {
        handleClose();
        setIsCanceled(false);
        if (type === "imageDescription")
            await createWithAiForMultipleValues("image", quantity, delayTime * 1000, true);
        else
            await createWithAiForMultipleValues(type, quantity, delayTime * 1000);
        setIsCanceled(true);
    };

    const handleCancel = () => {
        cancelCreation();
        setIsCanceled(true);
    };

    // render -------------------------------------------------------------
    return (
        <>
            {/* Progress Bar - Fixed at the Top */}
            {!isCanceled && (
                <Box sx={{ position: "fixed", top: 0, left: 0, width: "100%", zIndex: 1500 }}>
                    <LinearProgress variant="determinate" value={progress} />
                </Box>
            )}
            {!isCanceled && (
                <Box
                    sx={{
                        position: "fixed",
                        top: 8,
                        left: "13%",
                        transform: "translateX(-50%)",
                        zIndex: 1500,
                        bgcolor: "rgba(0, 0, 0, 1.5)",
                        color: "white",
                        py: 0.8,
                        px: 1.5,
                        borderRadius: "8px",
                        boxShadow: "0px 2px 5px rgba(0,0,0,0.2)",
                        fontWeight: 500,
                        minWidth: 320,
                        textAlign: "center",
                    }}
                >
                    <Typography variant="body2" sx={{ fontSize: "14px" }}>
                        {`בוצעו ${done}, נותרו ${remaining} מתוך ${total} (${progress.toFixed(2)}%)`}
                    </Typography>
                </Box>
            )}

            {isCanceled ? (
                <Button variant="contained" color="primary" onClick={handleOpen}>
                    יצירה אוטומטית
                </Button>
            ) : (
                <Button variant="contained" color="secondary" onClick={handleCancel} sx={{ minWidth: 118 }}>
                    ביטול יצירה
                </Button>
            )}

            <Dialog open={open} onClose={handleClose}>
                {/* <DialogTitle>בחירת סוג וכמות ליצירה</DialogTitle> */}
                <DialogContent>
                    {/* בחירת סוג וכמות ליצירה */}
                    <Box display="flex" gap={2} alignItems="center">
                        <FormControl fullWidth margin="dense" sx={{ flex: 1 }}>
                            <InputLabel>מה ליצור</InputLabel>
                            <Select value={type} onChange={(e) => setType(e.target.value)}>
                                <MenuItem value="sentence">משפטים</MenuItem>
                                <MenuItem value="definition">הגדרות</MenuItem>
                                <MenuItem value="imageDescription">תמונות עם תיאור</MenuItem>
                                <MenuItem value="image">תמונות ללא תיאור</MenuItem>
                            </Select>
                        </FormControl>

                        <TextField
                            label="כמה ליצור"
                            type="number"
                            fullWidth
                            margin="dense"
                            value={quantity}
                            onChange={(e) => setQuantity(Math.max(1, e.target.value))}
                            sx={{ flex: 1 }}
                        />

                        <TextField
                            label="זמן המתנה בין פעולות (בשניות)"
                            type="number"
                            fullWidth
                            margin="dense"
                            value={delayTime}
                            onChange={(e) => setDelayTime(Math.max(1, e.target.value))}
                            sx={{ flex: 1 }}
                        />
                    </Box>

                    {/* הוספת ההערות */}
                    <Box mt={2} p={2} bgcolor="#f8f8f8" borderRadius={2}>
                        <Typography variant="body2" color="textSecondary">
                            <strong>שים לב:</strong>
                            <ul>
                                <li>ניתן לבצע <strong>סינון/חיפוש</strong>, כדי ליצור עבור מילים מסויימות.</li>
                                <li>יש <strong>לבטל מצב שינה</strong> במחשב, כדי שהתהליך לא יפסק באמצע.</li>
                                <li>יש <strong>לחבר את המחשב לחשמל</strong> או לוודא שיש מספיק סוללה.</li>
                                <li>יש להשאיר את המחשב <strong>מחובר לאינטרנט</strong>.</li>
                                <li>יש לוודא שיש מספיק <strong>אשראי </strong>בספק ה-AI.</li>
                                <li>
                                    יש מילים שה-AI מונע ליצור עבורן תמונות (מטעמי בטיחות). במקרה כזה תוצג הודעת שגיאה* ולא תיווצר תמונה עבור אותה מילה.
                                </li>
                            </ul>
                            <Typography variant="body2" color="textSecondary" sx={{ direction: "rtl" }}>
                                *"Your request was rejected as a result of our safety system. Your prompt may contain text that is not allowed by our safety system".
                            </Typography>
                        </Typography>
                    </Box>
                </DialogContent>

                {/* כפתורי פעולה */}
                <DialogActions sx={{ display: "flex", padding: 2 }}>
                    <Button onClick={handleCreate} color="primary" variant="contained">
                        צור
                    </Button>
                    <Button onClick={handleClose} color="secondary" variant="outlined">
                        ביטול
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
});

export default AutoCreation;