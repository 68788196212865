import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { AppBar, Toolbar, IconButton, Typography, Box, Button, Drawer, Divider } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useLocation } from 'react-router-dom';
import UserNameDisplay from '../User/UserNameDisplay';
import Logout from '../User/Logout';
import NoToolsMessage from './NoToolsMessage';
import './Layout.css';

const Layout = observer(({ children, tools, drawerTools }) => {
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const location = useLocation(); // לקבל את המיקום הנוכחי

    if (!drawerTools || drawerTools.length === 0) {
        drawerTools = [<NoToolsMessage />];
    }

    const toggleDrawer = () => {
        setDrawerOpen(!isDrawerOpen);
    };

    const isActive = (path) => location.pathname === path; // פונקציה שבודקת אם ה-URL תואם

    return (
        <div className="Layout">
            <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh", dir: "rtl" }}>
                {/* פס ניווט */}
                <AppBar position="static" sx={{
                    height: 50,
                    justifyContent: "center", // מרכז את הכלים בציר האופקי
                    // alignItems: "center", // מרכז את הכלים בציר האנכי
                }}>
                    <Toolbar disableGutters sx={{ padding: 1 }}>
                        {/* אייקון תפריט בצד ימין */}
                        <IconButton color="inherit" onClick={toggleDrawer}>
                            <MenuIcon />
                        </IconButton>

                        {/* שם האתר */}
                        <Typography variant="h6" sx={{ p: 0.5 }}>
                            Lexillon Admin
                        </Typography>

                        {/* כפתורים במרכז */}
                        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Button
                                className={`nav-button ${isActive('/dictionary') ? 'active' : ''}`}
                                component={Link}
                                to="/dictionary"
                            >
                                מילונים
                            </Button>
                            <Button
                                className={`nav-button ${isActive('/ai') ? 'active' : ''}`}
                                component={Link}
                                to="/ai"
                            >
                                AI
                            </Button>
                            <Button
                                className={`nav-button ${isActive('/categories') ? 'active' : ''}`}
                                component={Link}
                                to="/categories"
                            >
                                קטגוריות
                            </Button>
                            <Divider
                                orientation="vertical"
                                flexItem
                                sx={{ mx: 2, borderColor: 'grey.400' }}
                            />
                            <Button
                                className={`nav-button ${isActive('/customers') ? 'active' : ''}`}
                                component={Link}
                                to="/customers"
                            >
                                לקוחות
                            </Button>
                            <Button
                                className={`nav-button ${isActive('/test-paypal') ? 'active' : ''}`}
                                component={Link}
                                to="/test-paypal"
                            >
                                בדיקת פאייפאל
                            </Button>
                        </Box>

                        {/* כפתור יציאה ושם משתמש בצד שמאל */}
                        <Box sx={{ display: 'flex', alignItems: 'center', ml: 'auto' }}>
                            <UserNameDisplay />
                            <Logout />
                        </Box>
                    </Toolbar>
                </AppBar>

                {/* פס כלים שימושיים */}
                <Box
                    sx={{
                        bgcolor: "primary.light",
                        p: 1,
                        display: "flex",
                        gap: 2,
                        height: 50,
                        justifyContent: "center", // מרכז את הכלים בציר האופקי
                        alignItems: "center", // מרכז את הכלים בציר האנכי
                    }}
                >
                    {tools.map((tool, index) => (
                        <React.Fragment key={index}>
                            {tool}
                        </React.Fragment>
                    ))}
                </Box>

                {/* גוף הדף */}
                <Box sx={{ flexGrow: 1, p: 0 }}>{children}</Box>

                {/* חלונית נפתחת מצד ימין */}
                <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer}>
                    <Box className="rtl-scroll" sx={{ width: 310, px: 1, py: 2 }}>
                        {/* <Typography variant="h6">כלים נוספים</Typography> */}
                        {drawerTools.map((tool, index) => (
                            <React.Fragment key={index}>
                                {tool}
                                {index < drawerTools.length - 1 && (
                                    <Divider
                                        sx={{
                                            my: 3, // רווחים מעל ומתחת
                                            borderColor: 'primary.main', // צבע ראשי
                                            borderWidth: 4, // עובי הקו
                                            // borderStyle: 'dashed', // סגנון הקו (אפשר solid, dashed, וכו')
                                        }}
                                    />
                                )}
                            </React.Fragment>
                        ))}
                    </Box>
                </Drawer>
            </Box>
        </div>
    );
});

export default Layout;
