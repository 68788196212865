import React, { useState } from "react";
import { Accordion, AccordionSummary, AccordionDetails, CardMedia, Typography, Grid, Box, Button, IconButton, CircularProgress, TextField } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import CheckIcon from "@mui/icons-material/Check";
import { observer } from "mobx-react-lite";
import additionalInfo from "../../../Mobx/AdditionalInfo";

const NewImageSection = observer(() => {
    const newInfo = additionalInfo.newInfo;
    const expanded = additionalInfo.expandedState;
    const loading = additionalInfo.loading;

    const handleToggle = () => {
        additionalInfo.setExpandedState({ ...expanded, images: !expanded.images });
    };

    // ________________________________________________________
    const [loadingAddExistsInfo, setLoadingAddExistsInfo] = useState(false);
    const handleAddExistsInfo = async (image, index) => {
        setLoadingAddExistsInfo(true);
        await additionalInfo.addExistsInfo(image, null, null, null, "images", index);
        setLoadingAddExistsInfo(false);
    };

    // ________________________________________________________
    const [loadingDescriptionAndImageLoading, setLoadingDescriptionAndImage] = useState(false);

    const handleCreateDescriptionAndImage = async () => {
        setLoadingDescriptionAndImage(true);

        await additionalInfo.createWithAi("imageDescription");
        const description = newInfo.imageDescription?.[newInfo.imageDescription.length - 1];
        await additionalInfo.createWithAi("image", description);

        setLoadingDescriptionAndImage(false);
    }

    // ________________________________________________________
    return (
        <Grid item xs={12}>
            <Accordion expanded={expanded.images} onChange={() => handleToggle("images")} sx={{ boxShadow: 3, borderRadius: 2, width: "100%" }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">יצירת תמונות ({newInfo.image?.length || 0})</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ width: "100%", textAlign: "center" }}>

                    {/* כפתור יצירת תמונה */}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => additionalInfo.createWithAi("image")}
                        sx={{ mb: 2, mr: 1 }}
                        disabled={loading.create.image}
                    >
                        {loading.create.image ? <CircularProgress size={24} /> : "צור תמונה"}
                    </Button>

                    {/* כפתור יצירת תיאור */}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => additionalInfo.createWithAi("imageDescription")}
                        sx={{ mb: 2, mr: 1 }}
                        disabled={loading.create.imageDescription}
                    >
                        {loading.create.imageDescription ? <CircularProgress size={24} /> : "צור תיאור"}
                    </Button>

                    {/* כפתור יצירת תיאור ותמונה */}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleCreateDescriptionAndImage}
                        sx={{ mb: 2 }}
                        disabled={loadingDescriptionAndImageLoading}
                    >
                        {loadingDescriptionAndImageLoading ? <CircularProgress size={24} /> : "צור תיאור ותמונה"}
                    </Button>

                    {/* תמונות */}
                    {newInfo.image?.length > 0 ? (
                        <Grid container spacing={1.5} justifyContent="center">
                            {newInfo.image.map((image, index) =>
                                image ? (
                                    <Grid item key={index} sx={{ position: "relative" }}>
                                        <CardMedia component="img" height="190" image={image} alt={`תמונה ${index + 1}`} sx={{ borderRadius: 2, boxShadow: 2, maxWidth: 200 }} />
                                        <Box sx={{ position: "absolute", top: 12, right: 0, display: "flex", gap: 1 }}>
                                            <IconButton
                                                sx={{ bgcolor: "rgba(255,255,255,0.7)" }}
                                                onClick={() => handleAddExistsInfo(image, index)}
                                                disabled={additionalInfo.loading.addExistsInfo.images?.[index] || loadingAddExistsInfo}
                                            >
                                                {additionalInfo.loading.addExistsInfo.images?.[index] ?
                                                    <CircularProgress size={24} /> :
                                                    <SaveIcon sx={{
                                                        color: loadingAddExistsInfo
                                                            ? "gray" // צבע האייקון כאשר הכפתור מנוטרל
                                                            : "primary.main" // הצבע הרגיל של האייקון
                                                    }} />}
                                            </IconButton>
                                            <IconButton
                                                sx={{ bgcolor: "rgba(255,255,255,0.7)" }}
                                                onClick={() => additionalInfo.deleteNewInfo("image", index)}
                                                disabled={loadingAddExistsInfo}
                                            >
                                                <DeleteIcon
                                                    sx={{
                                                        color: loadingAddExistsInfo
                                                            ? "gray" // צבע האייקון כאשר הכפתור מנוטרל
                                                            : "error.main" // הצבע הרגיל של האייקון
                                                    }}
                                                />
                                            </IconButton>
                                        </Box>
                                    </Grid>
                                ) : null
                            )}
                        </Grid>
                    ) : (
                        <Typography variant="body2" color="textSecondary">לא נוצרו תמונות</Typography>
                    )}

                    {/* תיאורים */}
                    <div style={{ marginTop: 12 }}>
                        {newInfo.imageDescription?.length > 0 ? (
                            newInfo.imageDescription.map((description, index) =>
                                <Box key={index} sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", bgcolor: "#f0f0f0", p: 1, borderRadius: 2, mt: 0.5 }}>
                                    <Box sx={{ display: "flex", gap: 0, mb: 1 }}>
                                        <IconButton
                                            onClick={() => additionalInfo.createWithAi("image", description)}
                                            disabled={loading.create.image}
                                        >
                                            {description && (loading.create.image ? <CircularProgress size={24} /> : <CheckIcon color="primary" />)}
                                        </IconButton>
                                        <IconButton onClick={() => additionalInfo.deleteNewInfo("imageDescription", index)}>
                                            <DeleteIcon color="error" />
                                        </IconButton>
                                    </Box>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        multiline
                                        minRows={1}
                                        value={description}
                                        onChange={(e) => additionalInfo.editNewInfo("imageDescription", index, e.target.value)}
                                        sx={{ direction: /^[\u0590-\u05FF]/.test(description) ? "ltr" : "rtl" }}
                                    />
                                </Box>
                            )
                        ) : (
                            <Typography variant="body2" color="textSecondary">לא נוצרו תיאורים</Typography>
                        )}
                    </div>
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
});

export default NewImageSection;
