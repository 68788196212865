import React from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography, Grid, Box, Button, IconButton, TextField, CircularProgress } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import { observer } from "mobx-react-lite";
import additionalInfo from "../../../Mobx/AdditionalInfo";

const NewDefinitionSection = observer(() => {
    const newInfo = additionalInfo.newInfo;
    const expanded = additionalInfo.expandedState;
    const loading = additionalInfo.loading;

    const handleToggle = () => {
        additionalInfo.setExpandedState({ ...expanded, definitions: !expanded.definitions });
    };

    return (
        <Grid item xs={12}>
            <Accordion expanded={expanded.definitions} onChange={() => handleToggle("definitions")} sx={{ boxShadow: 3, borderRadius: 2, width: "100%" }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">יצירת הגדרות ({newInfo.definition?.length || 0})</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ width: "100%", textAlign: "center" }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => additionalInfo.createWithAi("definition")}
                        sx={{ mb: 2 }}
                        disabled={loading.create.definition}
                    >
                        {loading.create.definition ? <CircularProgress size={24} /> : "צור הגדרה"}
                    </Button>
                    <Box>
                        {newInfo.definition?.length > 0 ? (
                            newInfo.definition.map((definition, index) =>
                                <Box key={index} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", bgcolor: "#f0f0f0", p: 1, borderRadius: 2, mt: 0.5 }}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        multiline
                                        minRows={1}
                                        value={definition}
                                        onChange={(e) => additionalInfo.editNewInfo("definition", index, e.target.value)}
                                        sx={{ direction: /^[\u0590-\u05FF]/.test(definition) ? "ltr" : "rtl" }}
                                    />
                                    <Box sx={{ display: "flex", gap: 0, ml: 1 }}>
                                        <IconButton
                                            onClick={() => additionalInfo.addExistsInfo(null, null, definition, null, "definitions", index)}
                                            disabled={additionalInfo.loading.addExistsInfo.definitions?.[index]}
                                        >
                                            {definition && (additionalInfo.loading.addExistsInfo.definitions?.[index] ? <CircularProgress size={24} /> : <SaveIcon color="primary" />)}
                                        </IconButton>
                                        <IconButton onClick={() => additionalInfo.deleteNewInfo("definition", index)}>
                                            <DeleteIcon color="error" />
                                        </IconButton>
                                    </Box>
                                </Box>
                            )
                        ) : (
                            <Typography variant="body2" color="textSecondary">לא נוצרו הגדרות</Typography>
                        )}
                    </Box>
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
});

export default NewDefinitionSection;
