import React from "react";
import { Card, CardContent, Typography, Table, TableBody, TableCell, TableContainer, TableRow, Paper } from "@mui/material";

const InputPlaceholderGuide = () => {
    return (
        <CardContent sx={{ p: 1 }}>
            {/* <Typography variant="h6" gutterBottom>
                שימוש בתיבת הקלט
            </Typography> */}

            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>1. שימוש ב -field-</Typography>
            <Typography variant="body2">
                מחליף את <code>-field-</code> בערך מתאים מתוך המילון.
            </Typography>

            <TableContainer component={Paper} sx={{ mt: 1 }}>
                <Table size="small">
                    <TableBody>
                        <TableRow>
                            <TableCell sx={{ fontWeight: "bold" }}>זה</TableCell>
                            <TableCell sx={{ direction: 'rtl', textAlign: 'right' }}>
                                <code>-original- is translated as -trns-</code>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{ fontWeight: "bold" }}>יוחלף בזה</TableCell>
                            <TableCell sx={{ direction: 'rtl', textAlign: 'right' }}>
                                Apple is translated as תפוח
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>2. שימוש ב {'{field/} . . . {field}'}</Typography>
            <Typography variant="body2">
                מציג את . . . רק אם השדה קיימת.
            </Typography>

            <TableContainer component={Paper} sx={{ mt: 1 }}>
                <Table size="small">
                    <TableBody>
                        <TableRow>
                            <TableCell sx={{ fontWeight: "bold" }}>זה</TableCell>
                            <TableCell sx={{ direction: 'rtl', textAlign: 'right' }}>
                                <code>{"{category}The category is: -category-{/category}"}</code>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{ fontWeight: "bold" }}>יוחלף בזה אם יש קטגוריה</TableCell>
                            <TableCell sx={{ direction: 'rtl', textAlign: 'right' }}>
                                The category is: Fruits
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{ fontWeight: "bold" }}>אם אין קטגוריה זה לא יוצג בכלל</TableCell>
                            <TableCell sx={{ direction: 'rtl', textAlign: 'right' }}>

                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>3. שדות זמינים</Typography>
            <Typography variant="body2" sx={{ direction: 'rtl', textAlign: 'right' }}>
                original, trns, category, pos, latin, olami, band, priority, abbreviation, alternative, pl, informal, pastSimple, pastPerfect, adjMore, adjMost, expPlural, opposite, cil, imageType, sentence, definition
            </Typography>

            <Typography variant="subtitle3" sx={{ fontWeight: "bold", mt: 1 }}>שדות נוספים עבור עברית לאנגלית:</Typography>
            <Typography variant="body2" sx={{ direction: 'rtl', textAlign: 'right' }}>
                value, gender, root, family, femaleform, pluralform, newHebrew, latinHebrew, amrBre
            </Typography>
        </CardContent>
    );
};

export default InputPlaceholderGuide;
