import React from 'react';
import urls from '../../Mobx/Urls';
import './LoginWhiteOAuth.css';

// Login with OAuth 2.0 - using Google
function LoginWhiteGoogle() {
    const handleGoogleLogin = (event) => {
        // Prevents the default behavior of the button (submitting the form)
        event.preventDefault();

        // The URL of the page the user will be returned to after logging in
        const redirectTo = process.env.NODE_ENV === "development" ?
            `https://localhost:3000/` : `https://admin.lexillon.co.il/`;

        // The query parameters that will be included in the URL when the user is returned to the page
        // const queryParams = ['firstName', 'lastName', 'role'];
        const queryParams = [];

        // Encoding JSON to Base64 using window.btoa
        const state = btoa(JSON.stringify({ redirectTo, queryParams }));

        // Building a URL for Google with a `state` parameter
        const googleAuthUrl = `${urls.user.loginWithGoogle}?state=${encodeURIComponent(state)}`;

        // Redirects the user to the server to connect with Google
        window.location.href = googleAuthUrl;
    };

    return (
        <div className='LoginWhiteOAuth'>
            <button type="button" onClick={handleGoogleLogin}>
                כניסה עם גוגל
                <img src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" alt="Google logo" />
            </button>
        </div>
    );
}

export default LoginWhiteGoogle;
