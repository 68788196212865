import React from "react";
import { Box, Grid } from "@mui/material";
import { observer } from "mobx-react-lite";
import ImageSection from "./NewImageSection";
import SentenceSection from "./NewSentenceSection";
import DefinitionSection from "./NewDefinitionSection";
import StorySection from "./NewStorySection";

const NewInfo = observer(() => {
    const headerHeight = 104;
    const height = `calc(100vh - ${headerHeight}px)`;

    return (
        <Box
            sx={{
                height,
                overflowY: "auto",
                overflowX: "hidden",
                pt: 0.5,
                px: 0,
                pb: 0.5,
                direction: "rtl",
                bgcolor: "#f9f9f9",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
            }}
        >
            <Box sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", direction: "ltr" }}>
                <Grid container spacing={0.5} justifyContent="center" sx={{ width: "100%" }}>
                    <ImageSection />
                    <SentenceSection />
                    <DefinitionSection />
                    <StorySection />
                </Grid>
            </Box>
        </Box>
    );
});

export default NewInfo;
