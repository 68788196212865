import { filterDictionaryByAdditionalInfo, filterDictionaryByAdditionalInfoDate } from './additionalInfo';

// ______________________________________________________________________________________
export function sort(data) {
    return new Promise((resolve) => {
        data.sort((a, b) => {
            // Compare originalWithoutPunctuation
            if (a.originalWithoutPunctuation < b.originalWithoutPunctuation) return -1;
            if (a.originalWithoutPunctuation > b.originalWithoutPunctuation) return 1;

            // If originalWithoutPunctuation is the same, compare original
            if (a.original < b.original) return -1;
            if (a.original > b.original) return 1;

            // If original is the same, compare pos
            if (a.pos && b.pos) {
                if (a.pos < b.pos) return -1;
                if (a.pos > b.pos) return 1;
            } else {
                if (a.pos) { return -1; }
                if (b.pos) { return 1; }
            }

            // If pos is the same, compare priority
            if (a.priority < b.priority) return -1;
            if (a.priority > b.priority) return 1;

            // If all are the same
            return 0;
        });
        resolve();
    });
}

export function sortByField(data, field = 'originalWithoutPunctuation', order = 'asc') {
    const sortField = field === 'original' ? 'originalWithoutPunctuation' : field;

    return data.sort((a, b) => {
        let aField = a[sortField];
        let bField = b[sortField];

        // If the field is undefined or null, we will replace it with an empty value to avoid problematic comparisons   
        aField = aField !== undefined && aField !== null ? aField : '';
        bField = bField !== undefined && bField !== null ? bField : '';

        // If the field is 'categories', refer to the first value in the array and compare by name
        if (sortField === 'categories') {
            aField = Array.isArray(aField) && aField.length > 0 ? aField[0]?.name : ''; // השתמש בשם הראשון במערך או מחרוזת ריקה אם המערך ריק
            bField = Array.isArray(bField) && bField.length > 0 ? bField[0]?.name : '';
        }

        // Comparison of strings
        if (typeof aField === 'string' && typeof bField === 'string') {
            return order === 'asc' ? aField.localeCompare(bField) : bField.localeCompare(aField);
        } else {
            // Comparison for numbers or other types
            return order === 'asc'
                ? (aField > bField ? 1 : -1)
                : (aField < bField ? 1 : -1);
        }
    });
}

// ______________________________________________________________________________________
export function filter(data, search) {
    return new Promise((resolve) => {
        const { text, field, includes, additionalInfoSearch, additionalInfoDate, range } = search;

        let filteredData = data;

        const excludedFields = ['_id', 'createdAt', 'updatedAt'];

        if (text && field) {
            if (field === 'all') {
                filteredData = data.filter(item =>
                    Object.keys(item).some(key =>
                        !excludedFields.includes(key) &&
                        item[key] &&
                        (
                            typeof item[key] === 'string' &&
                            (includes ? item[key]?.includes(text) : item[key]?.startsWith(text)) ||

                            typeof item[key] === 'number' &&
                            (includes ? item[key].toString()?.includes(text) : item[key].toString()?.startsWith(text)) ||

                            Array.isArray(item[key]) &&
                            item[key].some(subValue => includes ? subValue.name?.includes(text) : subValue.name?.startsWith(text))
                        )
                    )
                );
            }
            else if (field === 'categories') {
                filteredData = data.filter(item =>
                    item[field] &&
                    item[field].some(category => includes ? category.name?.includes(text) : category.name?.startsWith(text))
                );
            }
            else {
                filteredData = data.filter(item =>
                    !excludedFields?.includes(field) &&
                    item[field] &&
                    (
                        typeof item[field] === 'string' &&
                        (includes ? item[field]?.includes(text) : item[field]?.startsWith(text)) ||

                        typeof item[field] === 'number' &&
                        (includes ? item[field].toString()?.includes(text) : item[field].toString()?.startsWith(text))
                    )
                );
            }
        }

        if (additionalInfoSearch)
            filteredData = filterDictionaryByAdditionalInfo(filteredData, additionalInfoSearch);

        if (additionalInfoDate)
            filteredData = filterDictionaryByAdditionalInfoDate(filteredData, additionalInfoDate);

        if (range)
            filteredData = filterByRange(filteredData, range);

        // setTimeout(() => resolve(filteredData), 1000);
        resolve(filteredData);
    });
}

function filterByRange(data, range) {
    const fromIndex = range.from ? Math.max(Number(range.from) - 1, 0) : 0; // מתאים למבנה מבוסס 1
    const toIndex = range.to ? Number(range.to) : data.length; // עד סוף הרשימה אם לא נבחר ערך
    return data.slice(fromIndex, toIndex);
};

// ______________________________________________________________________________________
export async function wait(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}