import React, { useState } from "react";
import { Button, CircularProgress, Snackbar, Alert, Stack, Typography } from "@mui/material";
import axios from "axios";
import urls from "../../../Mobx/Urls";

const ReloadingRamMemory = () => {
    const [loading_test, setLoading_test] = useState(false);
    const [loading_production, setLoading_production] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });

    const handleReloadDictionaries = async (environment) => {
        environment === "test" ? setLoading_test(true) : setLoading_production(true);
        try {
            const url = urls.dictionary.reloading_ram_memory + environment;
            const response = await axios.get(url, { withCredentials: true, });

            const serverName = environment === 'test' ? 'בשרת הבדיקות' : 'בשני שרתי המוצר';

            if (response.status === 200) {
                setSnackbar({
                    open: true,
                    message: `המילונים נטענו מחדש בהצלחה ${serverName}!`,
                    severity: "success",
                });
            } else {
                setSnackbar({
                    open: true,
                    message: `נכשלה טעינת המילונים ${serverName}.`,
                    severity: "error",
                });
            }
        } catch (error) {
            console.error("Error reloading dictionaries:", error);
            setSnackbar({
                open: true,
                message: `אירעה שגיאה בעת טעינת המילונים מחדש. ${error.message}. ${error?.response?.data}`,
                severity: "error",
            });
        } finally {
            environment === "test" ? setLoading_test(false) : setLoading_production(false);
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbar((prev) => ({ ...prev, open: false }));
    };

    return (
        <Stack direction="column" spacing={1} className="export-to-excel" sx={{ width: "100%" }}>
            <Typography variant="h7" gutterBottom>
                עדכון הזיכרון ראם בשרת:
            </Typography>

            {/* יישור הכפתורים לרוחב מלא בגודל שווה */}
            <Stack direction="row" spacing={1} sx={{ width: "100%" }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleReloadDictionaries("test")}
                    disabled={loading_test}
                    startIcon={loading_test && <CircularProgress size={20} />}
                    sx={{ flex: 1, minWidth: 0 }}
                >
                    {loading_test ? "טוען..." : "בדיקות"}
                </Button>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleReloadDictionaries("production")}
                    disabled={loading_production}
                    startIcon={loading_production && <CircularProgress size={20} />}
                    sx={{ flex: 1, minWidth: 0 }}
                >
                    {loading_production ? "טוען..." : "מוצר"}
                </Button>
            </Stack>

            <Typography variant="body2" color="textSecondary" textAlign="center">
                בשרת מוצר מתבצע עדכון אוטומטי בשעה 2:00.
            </Typography>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: "100%" }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Stack>
    );
};

export default ReloadingRamMemory;
