import axios from 'axios';
import urls from './Urls';
import { action, makeObservable, observable, runInAction } from "mobx";
import { jwtDecode } from 'jwt-decode';
import dictionary from './Dictionary';
import dictionaryLog from './DictionaryLog';
import customers from './Customers';
import groups from './Groups';
import Swal from 'sweetalert2';
import { startTokenCheck, stopTokenCheck } from '../Utils/user';

class User {
    name = null;
    role = null;
    tokenCheckInterval = null; // variable for checking token

    constructor() {
        makeObservable(this, {
            name: observable,
            role: observable,
            login: action,
            logout: action
        });
        this.name = localStorage.getItem("name");
        this.role = localStorage.getItem("role");

        if (!this.tokenCheckInterval)
            this.tokenCheckInterval = startTokenCheck(); // start checking token
    }

    async login(email, password) {
        let response;
        if (email && password) { // login with email and password
            response = await axios.post(urls.user.login, { email, password }, { withCredentials: true });
        }
        else { // login with OAuth 2.0 - using Google as an example
            response = await axios.get(urls.user.getOne, { withCredentials: true });
        }
        const token = response.data.token;
        let welcomeMessage;

        runInAction(() => {
            const jwtPayload = jwtDecode(token);
            if (jwtPayload.role === 'admin') {
                this.role = jwtPayload.role;
                this.name = jwtPayload.firstName + ' ' + jwtPayload.lastName;

                localStorage.setItem("name", this.name);
                localStorage.setItem("role", this.role);

                email && dictionary.getAll();
                welcomeMessage = `Welcome ${this.name}!`;

                if (!this.tokenCheckInterval)
                    this.tokenCheckInterval = startTokenCheck(); // start checking token
            } else {
                welcomeMessage = `הגישה למנהלים בלבד`;
                this.logout();
            }
        });
        return welcomeMessage;
    }

    async logout() {
        try {
            // Clear the token from the cookie
            const response = await axios.post(urls.user.logout, {}, { withCredentials: true });

            // Clear the session from the Ministry of Education of Education
            setTimeout(() => { // wait 5 seconds before redirecting, To be able to read the error message.
                if (this.name) return; // if the user is not logged in, don't redirect
                const redirectTo = process.env.NODE_ENV === "development" ?
                    `https://localhost:3000/` : `https://admin.lexillon.co.il/`;
                window.location.href = `${urls.user.logoutMinistryOfEducation}?redirectTo=${redirectTo}`;
            }, 5000);

            // Clear the Mobx data
            this.clear();

            // Show success message
            console.log(response.data);
            // Swal.fire({
            //     title: 'You have safely logged out!',
            //     text: 'All data has been deleted from the browser.',
            //     icon: 'success',
            //     confirmButtonText: 'OK',
            //     width: 'auto'
            // });

        } catch (error) {
            // Show error message
            console.log('error logout: ', error);
            Swal.fire({
                title: 'Warning!',
                text: 'There was an issue deleting the token from the cookie. Please urgently contact support.',
                icon: 'warning',
                confirmButtonText: 'OK',
                width: 'auto'
            });

        } finally {
            // Clear the Mobx data
            this.clear();
        }
    }

    clear() {
        this.name = null;
        this.role = null;
        localStorage.removeItem("name");
        localStorage.removeItem("role");
        stopTokenCheck(this.tokenCheckInterval); // stop checking token
        this.tokenCheckInterval = null;

        dictionary.clear();
        dictionaryLog.clear();
        customers.clear();
        groups.clear();
    }
}

const user = new User();
export default user;
