import React, { useState, useEffect } from "react";
import { Button, TextField, CircularProgress, Stack, Typography } from "@mui/material";
import dictionary from "../../../Mobx/Dictionary";

const STORAGE_KEY_RANGE = "filter_range_preferences";

// function to get initial range filter from localStorage
const getInitialRangeFilter = () => {
    try {
        const savedRange = localStorage.getItem(STORAGE_KEY_RANGE);
        return savedRange ? JSON.parse(savedRange) : { from: "", to: "" };
    } catch (error) {
        console.error("Error loading range filter preferences:", error);
        return { from: "", to: "" };
    }
};

const FilterDictionaryByRange = () => {
    // State
    const [range, setRange] = useState(getInitialRangeFilter);
    const [isLoading, setIsLoading] = useState(false);

    // Update localStorage when range filter changes
    useEffect(() => {
        localStorage.setItem(STORAGE_KEY_RANGE, JSON.stringify(range));
    }, [range]);

    // Handlers for changing range values
    const handleFromChange = (event) => {
        const value = event.target.value;
        if (/^\d*$/.test(value) && value !== "0") { // Allow only digits and not 0
            setRange((prev) => ({ ...prev, from: value === "" ? "" : Number(value) }));
        }
    };

    const handleToChange = (event) => {
        const value = event.target.value;
        if (/^\d*$/.test(value) && value !== "0") { // Allow only digits and not 0
            setRange((prev) => ({ ...prev, to: value === "" ? "" : Number(value) }));
        }
    };

    // Function to apply range filter
    const handleApply = async () => {
        setIsLoading(true);

        dictionary.setCurrent(null, {
            text: dictionary.search.text,
            field: dictionary.search.field,
            includes: dictionary.search.includes,
            additionalInfoSearch: null,
            range: range, // Pass range filter
            date: null,
        });

        setIsLoading(false);
    };

    // Function to cancel range filter
    const handleCancelFilter = () => {
        dictionary.setCurrent(null, {
            text: dictionary.search.text,
            field: dictionary.search.field,
            includes: dictionary.search.includes,
        });
        // setRange({ from: "", to: "" });
    };

    return (
        <Stack spacing={1}>
            <Typography variant="h7" gutterBottom>
                סנן לפי טווח מיקום במילון
            </Typography>

            <Stack direction="row" spacing={1} justifyContent="center">
                <TextField
                    type="text"
                    label="מיקום מ (כולל)"
                    value={range.from}
                    onChange={handleFromChange}
                    autoComplete="off"
                    sx={{ minWidth: 80 }}
                />
                <TextField
                    type="text"
                    label="עד (כולל)"
                    value={range.to}
                    onChange={handleToChange}
                    autoComplete="off"
                    sx={{ minWidth: 80 }}
                />
            </Stack>

            <Stack direction="row" spacing={1}>
                <Button variant="contained" color="primary" onClick={handleApply} fullWidth disabled={isLoading}>
                    {isLoading ? <CircularProgress size={24} /> : "סנן"}
                </Button>
                <Button variant="outlined" color="secondary" onClick={handleCancelFilter} fullWidth>
                    ביטול
                </Button>
            </Stack>
        </Stack>
    );
};

export default FilterDictionaryByRange;
