import React from "react";
import { observer } from "mobx-react-lite";
import { Paper, Typography, Box, Tooltip } from "@mui/material";
import additionalInfo from "../../../Mobx/AdditionalInfo";
import LoadingIndicator from "../../SharedArea/Loading/LoadingIndicator";

const modelDescriptions = {
    // OpenAI
    "gpt-4": "חכם, איטי, יקר",
    "gpt-4-turbo": "בחירה מאוזנת (מומלץ)",
    "gpt-3.5-turbo": "פחות חכם, זול, מהיר",
    "gpt-4.5-preview": "גרסה מתקדמת של GPT-4",
    "o1": "מודל ניסיוני חדש",
    "o3-mini": "מודל קומפקטי עם ביצועים משופרים",
    // Anthropic
    "claude-3-opus-20240229": "המודל החזק ביותר של Anthropic",
    "claude-3-5-sonnet-20240620": "איזון בין ביצועים לעלות",
    "claude-3-5-haiku-20241022": "מהיר וזול אך פחות חכם",
    "claude-3-7-sonnet-20250219": "גרסה מעודכנת עם שיפורים באיכות ובמהירות",
    // DeepSeek
    "deepseek-chat": "שיחה ויצירת טקסט (מומלץ)",
    "deepseek-reasoner": "פתרון בעיות לוגיות מורכבות"
};

const modelPrices = {
    // https://platform.openai.com/docs/pricing
    "gpt-4": { input: 30.00, output: 60.00 },
    "gpt-4-turbo": { input: 10.00, output: 30.00 },
    "gpt-3.5-turbo": { input: 0.50, output: 1.50 },
    "dall-e-3": { "1024x1024": 0.04, "1024x1792": 0.08 },

    "claude-3-opus-20240229": { input: 0.015, output: 0.075 },
    "claude-3-5-sonnet-20240620": { input: 0.006, output: 0.03 },
    "claude-3-5-haiku-20241022": { input: 0.00025, output: 0.00125 },
    "claude-3-7-sonnet-20250219": { input: 0.005, output: 0.025 },
};

const temperatureLevels = [
    { value: 0.0, label: "0.0", description: "רמת דיוק גבוהה, פחות יצירתיות" },
    { value: 0.1, label: "0.1", description: "רמת דיוק גבוהה, פחות יצירתיות" },
    { value: 0.2, label: "0.2", description: "רמת דיוק גבוהה, פחות יצירתיות" },
    { value: 0.3, label: "0.3", description: "רמת דיוק גבוהה, פחות יצירתיות" },
    { value: 0.4, label: "0.4", description: "מאוזן" },
    { value: 0.5, label: "0.5", description: "מאוזן" },
    { value: 0.6, label: "0.6", description: "מאוזן" },
    { value: 0.7, label: "0.7", description: "יצירתי (מומלץ)" },
    { value: 0.8, label: "0.8", description: "יצירתי" },
    { value: 0.9, label: "0.9", description: "יצירתי" },
    { value: 1.0, label: "1.0", description: "מקסימום יצירתיות" }
];

const Provider = observer(() => {
    const handleProviderClick = async (provider) => {
        await additionalInfo.setSelectedProvider(provider);
    };

    const handleModelClick = async (model) => {
        await additionalInfo.setSelectedModel(model);
    };

    const handleTemperatureClick = async (temperature) => {
        await additionalInfo.setTemperature(temperature);
    };

    return (
        <div style={{ marginRight: "0px" }}>
            {/* Toolbar */}
            <Box sx={{ position: "absolute", top: 7, right: 550, display: "flex", alignItems: "center", gap: 2 }}>
                <LoadingIndicator isLoading={additionalInfo.loading.aiSettings} />
            </Box>

            <div style={{ display: "flex", flexDirection: "column", gap: "8px", marginTop: "10px" }}>
                {/* בחירת ספק */}
                <Typography variant="h6">ספק</Typography>
                <div style={{ display: "flex", gap: "10px" }}>
                    {additionalInfo.aiSettings.providers.options.map((provider) => (
                        <Paper
                            key={provider}
                            sx={{
                                p: 1,
                                cursor: "pointer",
                                backgroundColor: provider === additionalInfo.aiSettings.providers.selected ? "#1976d2" : "#f5f5f5",
                                color: provider === additionalInfo.aiSettings.providers.selected ? "white" : "black",
                                width: "120px",
                                textAlign: "center",
                                borderRadius: "8px",
                                transition: "0.3s",
                                "&:hover": {
                                    backgroundColor: provider === additionalInfo.aiSettings.providers.selected ? "#1565c0" : "#e0e0e0",
                                },
                            }}
                            onClick={() => handleProviderClick(provider)}
                        >
                            <Typography variant="body1" fontWeight="bold">{provider}</Typography>
                        </Paper>
                    ))}
                </div>

                {/* בחירת מודל */}
                <Typography variant="h6" sx={{ mt: 2 }}>מודל</Typography>
                <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
                    {additionalInfo.aiSettings.models[additionalInfo.aiSettings.providers.selected]?.options.map((model) => (
                        <Tooltip title={modelDescriptions[model] || "אין מידע"} arrow key={model}>
                            <Paper
                                sx={{
                                    p: 1,
                                    cursor: "pointer",
                                    backgroundColor: model === additionalInfo.aiSettings.models[additionalInfo.aiSettings.providers.selected].selected ? "#1976d2" : "#f5f5f5",
                                    color: model === additionalInfo.aiSettings.models[additionalInfo.aiSettings.providers.selected].selected ? "white" : "black",
                                    width: "165px",
                                    textAlign: "center",
                                    borderRadius: "8px",
                                    transition: "0.3s",
                                    "&:hover": {
                                        backgroundColor: model === additionalInfo.aiSettings.models[additionalInfo.aiSettings.providers.selected].selected ? "#1565c0" : "#e0e0e0",
                                    },
                                }}
                                onClick={() => handleModelClick(model)}
                            >
                                <Typography variant="body1" fontWeight="bold">{model}</Typography>
                            </Paper>
                        </Tooltip>
                    ))}
                </div>

                {/* בחירת טמפרטורה */}
                <Typography variant="h6" sx={{ mt: 2 }}>
                    טמפרטורה
                </Typography>
                <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
                    {temperatureLevels.map(({ value, label, description }) => {
                        const isDisabled = ["o1", "o3-mini"].includes(additionalInfo.aiSettings.models[additionalInfo.aiSettings.providers.selected]?.selected);
                        return (
                            <Tooltip title={description} arrow key={value}>
                                <Paper
                                    sx={{
                                        p: 1,
                                        cursor: isDisabled ? "not-allowed" : "pointer",
                                        backgroundColor: value === additionalInfo.aiSettings.temperature ? "#1976d2" : "#f5f5f5",
                                        color: value === additionalInfo.aiSettings.temperature ? "white" : "black",
                                        width: "50px",
                                        textAlign: "center",
                                        borderRadius: "8px",
                                        opacity: isDisabled ? 0.5 : 1,
                                        transition: "0.3s",
                                        "&:hover": {
                                            backgroundColor: isDisabled ? "#f5f5f5" : (value === additionalInfo.aiSettings.temperature ? "#1565c0" : "#e0e0e0"),
                                        },
                                    }}
                                    onClick={() => !isDisabled && handleTemperatureClick(value)}
                                >
                                    <Typography variant="body1" fontWeight="bold">{label}</Typography>
                                </Paper>
                            </Tooltip>
                        );
                    })}
                </div>
            </div>
        </div>
    );
});

export default Provider;
