import React, { useState, useEffect } from "react";
import { Button, Stack, Typography, CircularProgress } from "@mui/material";
import { LocalizationProvider, MobileDatePicker, MobileTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/he"; // Hebrew support

import dictionary from "../../../Mobx/Dictionary";

const LOCAL_STORAGE_KEY = "dateTimeRange";

const DateTimeRangePicker = () => {
    // ___________________________________________________________________________
    // Load stored date values from localStorage
    const loadStoredDates = () => {
        const storedData = localStorage.getItem(LOCAL_STORAGE_KEY);
        if (storedData) {
            try {
                const { startDate, endDate, startTime, endTime } = JSON.parse(storedData);
                return {
                    startDate: dayjs(startDate),
                    endDate: dayjs(endDate),
                    startTime: dayjs(startTime),
                    endTime: dayjs(endTime),
                };
            } catch (error) {
                console.error("Failed to parse stored date data:", error);
            }
        }
        return {
            startDate: dayjs(),
            endDate: dayjs(),
            startTime: dayjs().subtract(1, "hour"),
            endTime: dayjs(),
        };
    };

    const initialDates = loadStoredDates();

    // date
    const [startDate, setStartDate] = useState(initialDates.startDate);
    const [endDate, setEndDate] = useState(initialDates.endDate);
    const [startTime, setStartTime] = useState(initialDates.startTime);
    const [endTime, setEndTime] = useState(initialDates.endTime);

    // Save to localStorage
    const saveToLocalStorage = (newStartDate, newEndDate, newStartTime, newEndTime) => {
        localStorage.setItem(
            LOCAL_STORAGE_KEY,
            JSON.stringify({
                startDate: newStartDate.toISOString(),
                endDate: newEndDate.toISOString(),
                startTime: newStartTime.toISOString(),
                endTime: newEndTime.toISOString(),
            })
        );
    };

    // Update state and localStorage when date/time changes
    const handleStartDateChange = (newDate) => {
        setStartDate(newDate);
        saveToLocalStorage(newDate, endDate, startTime, endTime);
    };

    const handleEndDateChange = (newDate) => {
        setEndDate(newDate);
        saveToLocalStorage(startDate, newDate, startTime, endTime);
    };

    const handleStartTimeChange = (newTime) => {
        setStartTime(newTime);
        saveToLocalStorage(startDate, endDate, newTime, endTime);
    };

    const handleEndTimeChange = (newTime) => {
        setEndTime(newTime);
        saveToLocalStorage(startDate, endDate, startTime, newTime);
    };

    // ___________________________________________________________________________
    // loading
    const [isLoading, setIsLoading] = useState(false);

    // function - filter
    const handleApply = async () => {
        setIsLoading(true);

        // date range
        const from = startDate.hour(startTime.hour()).minute(startTime.minute());
        const to = endDate.hour(endTime.hour()).minute(endTime.minute());
        const dateRange = { from, to };

        // filter
        dictionary.setCurrent(null, {
            text: dictionary.search.text,
            field: dictionary.search.field,
            includes: dictionary.search.includes,
            additionalInfoSearch: null,
            additionalInfoDate: dateRange,
        });

        setIsLoading(false);
    };

    // function - cancel filter
    const handleCancelFilter = () => {
        // reset date
        const now = dayjs();
        setStartDate(now);
        setEndDate(now);
        setStartTime(now.subtract(1, "hour"));
        setEndTime(now);

        // reset filter
        dictionary.setCurrent(null, {
            text: dictionary.search.text,
            field: dictionary.search.field,
            includes: dictionary.search.includes,
        });

        // Clear localStorage
        localStorage.removeItem(LOCAL_STORAGE_KEY);
    };

    // ___________________________________________________________________________
    // render
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="he">
            <Stack spacing={1}>
                <Typography variant="h7" gutterBottom>
                    הצג ערכים שנעשה בהם שינוי
                </Typography>
                <Stack direction="row" spacing={1}>
                    <MobileDatePicker
                        label="מתאריך"
                        value={startDate}
                        onChange={handleStartDateChange}
                        format="DD/MM/YYYY"
                        slotProps={{ textField: { fullWidth: true } }}
                        sx={{ direction: "rtl" }}
                    />
                    <MobileTimePicker
                        label="בשעה"
                        value={startTime}
                        onChange={handleStartTimeChange}
                        format="HH:mm"
                        ampm={false}
                        slotProps={{ textField: { fullWidth: true } }}
                        sx={{ direction: "rtl" }}
                    />
                </Stack>
                <Stack direction="row" spacing={1}>
                    <MobileDatePicker
                        label="עד תאריך"
                        value={endDate}
                        onChange={handleEndDateChange}
                        format="DD/MM/YYYY"
                        slotProps={{ textField: { fullWidth: true } }}
                        sx={{ direction: "rtl" }}
                    />
                    <MobileTimePicker
                        label="בשעה"
                        value={endTime}
                        onChange={handleEndTimeChange}
                        format="HH:mm"
                        ampm={false}
                        slotProps={{ textField: { fullWidth: true } }}
                        sx={{ direction: "rtl" }}
                    />
                </Stack>
                <Stack direction="row" spacing={1}>
                    <Button variant="contained" color="primary" onClick={handleApply} fullWidth disabled={isLoading}>
                        {isLoading ? <CircularProgress size={24} /> : "סנן"}
                    </Button>
                    <Button variant="outlined" color="secondary" onClick={handleCancelFilter} fullWidth>
                        ביטול
                    </Button>
                </Stack>
            </Stack>
        </LocalizationProvider>
    );
};

export default DateTimeRangePicker;
