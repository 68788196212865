import React from "react";
import { Box, Grid } from "@mui/material";
import { observer } from "mobx-react-lite";
import ExistsImageSection from "./ExistsImageSection";
import ExistsSentenceSection from "./ExistsSentenceSection";
import ExistsDefinitionSection from "./ExistsDefinitionSection";
import ExistsStorySection from "./ExistsStorySection";

const ExistsInfo = observer(() => {

    const headerHeight = 104;
    const height = `calc(100vh - ${headerHeight}px)`;

    return (
        <Box
            sx={{
                height,
                overflowY: "auto",
                overflowX: "hidden",
                pt: 0.5,
                px: 0,
                pb: 0.5,
                direction: "rtl",
                bgcolor: "#f9f9f9",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
            }}
        >
            <Box sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", direction: "ltr" }}>
                <Grid container spacing={0.5} justifyContent="center" sx={{ width: "100%" }}>
                    <ExistsImageSection />
                    <ExistsSentenceSection />
                    <ExistsDefinitionSection />
                    <ExistsStorySection />
                </Grid>
            </Box>
        </Box>
    );
});

export default ExistsInfo;
