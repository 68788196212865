import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { observer } from 'mobx-react';
import dictionary from '../../../Mobx/Dictionary';
import groups from '../../../Mobx/Groups';
import { MultiSelect } from 'react-multi-select-component';
import Swal from 'sweetalert2';
import DeleteDictionaryEntry from './DeleteDictionaryEntry';
import DictionaryLogDetails from '../DictionaryLog/DictionaryLogDetails';
import DictionaryLogItem from '../DictionaryLog/DictionaryLogItem';
import './DictionaryCard.css';
import { convertMessageToHTML } from '../../../Utils/convertMessageToHTML';
import ReactDOMServer from 'react-dom/server';
import dictionaryLog from '../../../Mobx/DictionaryLog';
import { containsErrorKey } from '../../../Utils/error';

const DictionaryCard = observer(({ entry, selectedFields, isSelected }) => {
    const { register, handleSubmit, reset, setValue, watch } = useForm();
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const dictionaryType = dictionary.current.dictionaryType;

    useEffect(() => {
        const loadEntry = async () => {
            try {
                if (entry) {
                    Object.keys(entry).forEach((key) => {
                        if (selectedFields.includes(key)) {
                            if (key === 'updatedAt' || key === 'createdAt') {
                                const formattedDate = new Date(entry[key]).toLocaleString('he-IL', {
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    second: '2-digit',
                                });
                                setValue(key, formattedDate);
                            } else {
                                setValue(key, entry[key]);
                            }
                        }
                    });
                    if (entry.categories) {
                        const selected = entry.categories.map(category => ({
                            label: category.name,
                            value: category._id
                        }));
                        setSelectedCategories(selected);
                    }
                }
            } catch (error) {
                console.error('Failed to load entry:', error);
                Swal.fire({
                    title: 'Error!',
                    text: 'Failed to load entry',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            }
        };

        loadEntry();
    }, [dictionaryType, entry, setValue, selectedFields]);

    const onSubmit = async (data) => {
        setIsSubmitting(true);
        try {
            const message = await dictionary.update(entry?._id, { ...data, categories: selectedCategories });
            // Swal.fire({
            //     title: 'Success!',
            //     // text: message,
            //     // html: convertMessageToHTML(message),
            //     html: ReactDOMServer.renderToString(<DictionaryLogDetails data={message} />),
            //     // html: ReactDOMServer.renderToString(<DictionaryLogItem logItem={message} />),
            //     icon: 'success',
            //     confirmButtonText: 'OK',
            //     width: 'auto'
            // });
        } catch (error) {
            // const log = error.response.data;
            // dictionaryLog.addLog(log);
            // if (containsErrorKey(log))
            //     dictionary.setIsLoading('error');
            // Swal.fire({
            //     title: 'Error!',
            //     // text: `Failed to update entry.\nError: ${error}.\nError description: ${JSON.stringify(error.response.data).replace(/&quot;/g, '"')}`,
            //     html: convertMessageToHTML({ Error: error.message, description: error.response.data }),
            //     icon: 'error',
            //     confirmButtonText: 'OK',
            //     width: 'auto'
            // });
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleCategoryChange = (selected) => {
        setSelectedCategories(selected);
        setValue('categories', selected);
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className={`dictionary-card ${isSelected ? 'selected' : ''}`}>
                {selectedFields.map((field) => {
                    if (['pos', 'latin', 'olami', 'band', 'priority', 'imageType'].includes(field)) {
                        const options = groups[field][dictionaryType];
                        return (
                            <div className='selectDiv' key={field}>
                                <label>{field}</label>
                                <select {...register(field)} dir="auto">
                                    <option value="">--</option>
                                    {options?.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        );
                    }
                    else if (field === 'categories') {
                        const options = groups[field][dictionaryType].map(option => ({
                            label: option.name,
                            value: option._id
                        }));
                        return (
                            <div key={field} className="multi-select-container">
                                <label>{field}</label>
                                <MultiSelect
                                    options={options}
                                    value={selectedCategories}
                                    onChange={handleCategoryChange}
                                    labelledBy="Select categories"
                                />
                            </div>
                        );
                    } else if (['_id', 'updatedAt', 'createdAt'].includes(field)) {
                        return (
                            <div className='dateDiv' key={field}>
                                <label className="dateField-label">{field}</label>
                                <span className="dateField" dir="auto">{watch(field)}</span>
                            </div>
                        );
                    } else {
                        return (
                            <div className='textDiv' key={field}>
                                <label>{field}</label>
                                <input
                                    type="text"
                                    dir="auto"
                                    {...register(field, { required: field === 'original' })}
                                    autoComplete="off"
                                />
                            </div>
                        );
                    }
                })}
                {selectedFields.length > 0 &&
                    <button type="submit" disabled={isSubmitting}>
                        {isSubmitting ? '...שומר' : 'שמור'}
                    </button>}
                {selectedFields.length > 0 &&
                    <DeleteDictionaryEntry dictionaryType={dictionaryType} entryId={entry?._id} />}
            </form>
        </>
    );
});

export default DictionaryCard;
