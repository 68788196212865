import React from "react";
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import createInterceptors from './Utils/createInterceptors';
import { CssBaseline } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import Login from "./Components/User/Login";
import user from "./Mobx/User";
import LoadingPage from "./Components/SharedArea/Loading/LoadingPage";
import dictionary from "./Mobx/Dictionary";
import Routing from "./Components/Layout/Routing";
import { observer } from "mobx-react-lite";
import GlobalSnackbar from "./Components/SharedArea/GlobalSnackbar";
import './index.css';

createInterceptors();

// יצירת Cache עם תמיכה ב-RTL
const rtlCache = createCache({
    key: 'mui-rtl',
    stylisPlugins: [prefixer, rtlPlugin],
});

// יצירת theme עם RTL
const theme = createTheme({
    direction: "rtl",
    typography: {
        fontFamily: "'Arial', 'Roboto', sans-serif",
    },
    palette: {
        primary: {
            main: "#1976d2",
            light: "#63a4ff",
            dark: "#004ba0",
            contrastText: "#ffffff"
        },
        secondary: {
            main: "#dc004e",
            light: "#ff616f",
            dark: "#9a0036",
            contrastText: "#ffffff"
        },
        error: {
            main: "#f44336",
        },
        warning: {
            main: "#ff9800",
        },
        info: {
            main: "#2196f3",
        },
        success: {
            main: "#4caf50",
        },
        background: {
            default: "#f5f5f5",
            paper: "#ffffff"
        },
        text: {
            primary: "#000000",
            secondary: "#757575"
        },
        divider: "#bdbdbd",
        action: {
            hover: "rgba(0, 0, 0, 0.08)",
        },
        mode: "light" // או "dark"
    },
});

const App = observer(() => {
    return (
        <CacheProvider value={rtlCache}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <GlobalSnackbar />
                <Router>
                    {!user.name ? <Login /> :
                        dictionary.isLoading.all ? <LoadingPage /> : <Routing />
                    }
                </Router>
            </ThemeProvider>
        </CacheProvider>
    );
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
