import React, { useLayoutEffect } from 'react';
import { observer } from 'mobx-react-lite';
import CategoryList from './CategoryList';
import DictionaryTypeSelector from '../Dictionary/Tools/DictionaryTypeSelector';
import Layout from '../Layout/Layout';
import dictionary from '../../Mobx/Dictionary';
import CategoryCountDisplay from './CategoryCountDisplay';
import CreateCategory from './CreateCategory';

const CategoryPage = observer(() => {
    // _____________________________________________________________________
    useLayoutEffect(() => {
        // dictionary.setCurrent(null, { text: '', field: 'all' }); // -1 To prevent slow loading of the UI.
    }, []);

    // _____________________________________________________________________
    const tools = [
        <DictionaryTypeSelector />,
        <CategoryCountDisplay />,
        <CreateCategory />
    ];
    const drawerTools = [
    ];

    return (
        <div className="CategoryPage">
            <Layout tools={tools} drawerTools={drawerTools}>
                <CategoryList />
            </Layout>
        </div>
    );
});

export default CategoryPage;
