import React from 'react';
import urls from '../../Mobx/Urls';
import './LoginWhiteOAuth.css';

// Login with OAuth 2.0 - using the Ministry of Education
function LoginWithMinistryOfEducation() {
    const handleMinistryLogin = (event) => {
        // Prevents the default behavior of the button (submitting the form)
        event.preventDefault();

        // The URL of the page the user will be returned to after logging in
        const redirectTo = process.env.NODE_ENV === "development" ?
            `https://localhost:3000/` : `https://admin.lexillon.co.il/`;

        // The query parameters that will be included in the URL when the user is returned to the page
        // const queryParams = ['firstName', 'lastName', 'role'];
        const queryParams = [];

        // Encoding JSON to Base64 using window.btoa
        const state = btoa(JSON.stringify({ redirectTo, queryParams }));

        // Building a URL for the Ministry of Education with a `state` parameter
        const ministryAuthUrl = `${urls.user.loginWithMinistryOfEducation}?state=${encodeURIComponent(state)}`;

        // Redirects the user to the server to connect with the Ministry of Education
        window.location.href = ministryAuthUrl;
    };

    return (
        <div className='LoginWhiteOAuth'>
            <button type="button" onClick={handleMinistryLogin}>
                כניסה עם משרד החינוך
                <img src="https://myofek.cet.ac.il/static/media/idmLogo.22c64dea146b3326274f.png" alt="Ministry of Education logo" />
            </button>
        </div>
    );
}

export default LoginWithMinistryOfEducation;
