import React from "react";
import { Card, Typography } from "@mui/material";
import dictionary from '../../../Mobx/Dictionary';
import "./DictionaryCard.css";

const DictionaryCard = ({ data, isSelected = false }) => {
    const handleClick = () => {
        dictionary.setCurrent(null, null, data._id);
    };

    return (
        <Card
            className={`dictionary-card-ai ${isSelected ? "selected-ai" : ""}`}
            sx={{
                margin: "4px",
                px: "3px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                height: "120px",
                cursor: "pointer"
            }}
            onClick={handleClick}
        >
            <Typography variant="h6" component="div">
                {data.original}
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ fontSize: "1.2rem" }}>
                {data.trns}
            </Typography>
            <Typography variant="caption" color="text.secondary" sx={{ fontSize: "0.8rem" }}>
                {data.pos}
            </Typography>
        </Card>
    );
};

export default DictionaryCard;
