import React, { useState, useEffect } from "react";
import { Button, MenuItem, Select, FormControl, TextField, CircularProgress, Stack, Typography } from "@mui/material";
import additionalInfo from "../../../Mobx/AdditionalInfo";
import dictionary from "../../../Mobx/Dictionary";

const STORAGE_KEY = "filter_preferences";

// function to get initial filter from localStorage
const getInitialFilter = () => {
    try {
        const savedFilters = localStorage.getItem(STORAGE_KEY);
        return savedFilters ? JSON.parse(savedFilters) : { condition: "exactly", amount: 1, field: "sentences" };
    } catch (error) {
        console.error("Error loading filter preferences:", error);
        return { condition: "exactly", amount: 1, field: "sentences" };
    }
};

// FilterComponent
const FilterDictionaryByAdditionalInfo = () => {
    // ____________________________________________________________
    // State
    const [filter, setFilter] = useState(getInitialFilter);
    const [isLoading, setIsLoading] = useState(false);

    // ____________________________________________________________
    // Update localStorage when filter changes
    useEffect(() => {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(filter));
    }, [filter]);

    // Handlers for changing filter values
    const handleConditionChange = (event) => setFilter((prev) => ({ ...prev, condition: event.target.value }));
    const handleAmountChange = (event) => {
        const value = event.target.value;
        if (/^\d*$/.test(value)) { // רק מספרים שלמים
            setFilter((prev) => ({ ...prev, amount: value === "" ? "" : Number(value) }));
        }
    };
    const handleFieldChange = (event) => setFilter((prev) => ({ ...prev, field: event.target.value }));

    // ____________________________________________________________
    // Function to apply filter
    const handleApply = async () => {
        setIsLoading(true);

        // filter
        dictionary.setCurrent(null, {
            text: dictionary.search.text,
            field: dictionary.search.field,
            includes: dictionary.search.includes,
            additionalInfoSearch: filter,
            date: null
        });

        setIsLoading(false);
    };

    // Function to cancel filter
    const handleCancelFilter = () => {
        // reset filter
        dictionary.setCurrent(null, {
            text: dictionary.search.text,
            field: dictionary.search.field,
            includes: dictionary.search.includes,
        });
    };

    // ____________________________________________________________
    // Render
    return (
        <Stack spacing={1}>
            <Typography variant="h7" gutterBottom>
                הצג ערכים שיש להם
            </Typography>

            {/* Filter inputs */}
            <Stack direction="row" spacing={1} justifyContent="center">
                <FormControl sx={{ minWidth: 100 }}>
                    <Select value={filter.condition} onChange={handleConditionChange}>
                        <MenuItem value="less">פחות מ</MenuItem>
                        <MenuItem value="exactly">בדיוק</MenuItem>
                        <MenuItem value="more">יותר מ</MenuItem>
                    </Select>
                </FormControl>

                <TextField
                    type="text"
                    value={filter.amount}
                    onChange={handleAmountChange}
                    sx={{ minWidth: 30 }}
                />

                <FormControl sx={{ minWidth: 110 }}>
                    <Select value={filter.field} onChange={handleFieldChange}>
                        <MenuItem value="sentences">משפטים</MenuItem>
                        <MenuItem value="definitions">הגדרות</MenuItem>
                        <MenuItem value="images">תמונות</MenuItem>
                        <MenuItem value="stories">סיפורים</MenuItem>
                    </Select>
                </FormControl>
            </Stack>

            {/* Buttons */}
            <Stack direction="row" spacing={1}>
                <Button variant="contained" color="primary" onClick={handleApply} fullWidth disabled={isLoading}>
                    {isLoading ? <CircularProgress size={24} /> : "סנן"}
                </Button>
                <Button variant="outlined" color="secondary" onClick={handleCancelFilter} fullWidth>
                    ביטול
                </Button>
            </Stack>
        </Stack>
    );
};

export default FilterDictionaryByAdditionalInfo;
