import { useState, useEffect } from "react";
import { CircularProgress, Box } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

const LoadingIndicator = ({ isLoading }) => {
    const [showCheck, setShowCheck] = useState(false);
    const [wasLoading, setWasLoading] = useState(false); // משתנה לבדיקה אם הייתה טעינה

    useEffect(() => {
        if (isLoading) {
            setWasLoading(true); // כאשר מתחילה טעינה, נסמן שהייתה טעינה
        } else if (wasLoading) {
            setShowCheck(true);
            const timer = setTimeout(() => setShowCheck(false), 2000); // הווי ייעלם אחרי 2 שניות
            return () => clearTimeout(timer);
        }
    }, [isLoading, wasLoading]);

    return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%"
            sx={{ mt: 1.5 }}>
            {isLoading ? <CircularProgress /> : showCheck && <CheckIcon color="success" fontSize="large" />}
        </Box>
    );
};

export default LoadingIndicator;
