import React from "react";
import { Accordion, AccordionSummary, AccordionDetails, CardMedia, Typography, Grid, Box, IconButton, CircularProgress } from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";

import { observer } from "mobx-react-lite";
import additionalInfo from "../../../Mobx/AdditionalInfo";
import urls from "../../../Mobx/Urls";
import ImageDropzone from "../../SharedArea/ImageDropzone";

const ExistsImageSection = observer(() => {
    const existsInfo = additionalInfo.existsInfo;
    const expanded = additionalInfo.expandedState;
    const loading = additionalInfo.loading;

    const handleToggle = () => {
        additionalInfo.setExpandedState({ ...expanded, images: !expanded.images });
    };

    return (
        <Grid item xs={12}>
            <Accordion
                expanded={expanded.images}
                onChange={() => handleToggle("images")}
                sx={{ boxShadow: 3, borderRadius: 2, width: "100%" }}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">תמונות ({existsInfo.images?.length || 0})</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ width: "100%", textAlign: "center" }}>
                    <ImageDropzone />
                    {existsInfo.images?.length > 0 ? (
                        <Grid container spacing={1.5} justifyContent="center">
                            {existsInfo.images.map((image, index) =>
                                image.fileName ? (
                                    <Grid item key={index} sx={{ position: "relative" }}>
                                        <CardMedia
                                            component="img"
                                            height="190"
                                            image={`${urls.file.getImage}${image.fileName}`}
                                            alt={`תמונה ${index + 1}`}
                                            sx={{ borderRadius: 2, boxShadow: 2, maxWidth: 200 }}
                                        />
                                        <Box sx={{ position: "absolute", top: 12, right: 0, display: "flex", gap: 1 }}>
                                            {image.default ?
                                                <Typography
                                                    variant="body2"
                                                    color="primary"
                                                    sx={{
                                                        fontWeight: "bold",
                                                        px: 1.5,
                                                        py: 0.5,
                                                        mt: 0.5,
                                                        maxHeight: 30,
                                                        bgcolor: "rgba(255,255,255,0.7)", // רקע כהה למחצה
                                                        borderRadius: 1
                                                    }}
                                                >
                                                    ברירת מחדל
                                                </Typography>
                                                :
                                                <IconButton
                                                    sx={{ bgcolor: "rgba(255,255,255,0.7)" }}
                                                    onClick={() => additionalInfo.updateExistsInfo(existsInfo._id, "images", image._id, { default: true })}
                                                    disabled={loading.updateExistsInfo.images?.[image._id] === "default"}
                                                >
                                                    {loading.updateExistsInfo.images?.[image._id] === "default" ? <CircularProgress size={24} /> : <CheckIcon color="primary" />}
                                                </IconButton>
                                            }
                                            <IconButton
                                                sx={{ bgcolor: "rgba(255,255,255,0.7)" }}
                                                onClick={() => additionalInfo.deleteExistsInfo(existsInfo._id, "images", image._id)}
                                                disabled={loading.deleteExistsInfo.images?.[image._id]}
                                            >
                                                {loading.deleteExistsInfo.images?.[image._id] ? <CircularProgress size={24} /> : <DeleteIcon color="error" />}
                                            </IconButton>
                                        </Box>
                                    </Grid>
                                ) : null
                            )}
                        </Grid>
                    ) : (
                        <Typography variant="body2" color="textSecondary">
                            אין תמונות זמינות
                        </Typography>
                    )}
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
});

export default ExistsImageSection;
