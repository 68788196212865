import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Tabs, Tab, Grid, TextField, Button, Box, Dialog, DialogContent, DialogActions, Typography, List, ListItemButton, ListItemText } from "@mui/material";
import additionalInfo from "../../../Mobx/AdditionalInfo";
import dictionary from "../../../Mobx/Dictionary";
import InfoTooltip from "./InfoTooltip";
import LoadingIndicator from "../../SharedArea/Loading/LoadingIndicator";
import { replacePlaceholders } from "../../../Utils/additionalInfo";

const LOCAL_STORAGE_KEY_FOR_TAB = "selectedTab";
const LOCAL_STORAGE_KEY_FOR_PROMPT = "selectedPromptId"; // + _{selectedTab}

const Prompt = observer(({ height }) => {
    const [selectedTab, setSelectedTab] = useState(localStorage.getItem(LOCAL_STORAGE_KEY_FOR_TAB) || "sentence");
    const [editedPrompts, setEditedPrompts] = useState({});
    const [editedName, setEditedName] = useState({});
    const [processedTexts, setProcessedTexts] = useState({});
    const [selectedPromptId_forListItem, setSelectedPromptId_forListItem] = useState(null);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [promptToDelete, setPromptToDelete] = useState(null);

    const currentWord = dictionary.getOne(dictionary.current.value._id);

    useEffect(() => {
        const savedPromptId = localStorage.getItem(`${LOCAL_STORAGE_KEY_FOR_PROMPT}_${selectedTab}`);
        setSelectedPromptId_forListItem(savedPromptId || additionalInfo.aiSettings.prompts.selected[selectedTab]);
    }, [selectedTab]);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
        const savedPromptId = localStorage.getItem(`${LOCAL_STORAGE_KEY_FOR_PROMPT}_${newValue}`);
        setSelectedPromptId_forListItem(savedPromptId || additionalInfo.aiSettings.prompts.selected[newValue]);
        localStorage.setItem(LOCAL_STORAGE_KEY_FOR_TAB, newValue);
    };

    const handleListItemClick = (promptId) => {
        setSelectedPromptId_forListItem(promptId);
        localStorage.setItem(`${LOCAL_STORAGE_KEY_FOR_PROMPT}_${selectedTab}`, promptId);
    };

    const handleInputChange = (promptId, value) => {
        setEditedPrompts((prev) => ({ ...prev, [promptId]: value }));

        if (currentWord) {
            const newValue = replacePlaceholders(value, currentWord);
            setProcessedTexts((prev) => ({ ...prev, [promptId]: newValue }));
        }
    };

    const handleNameChange = (promptId, value) => {
        setEditedName((prev) => ({ ...prev, [promptId]: value }));
    };

    const handleUpdatePrompt = async (promptId) => {
        const currentPrompt = additionalInfo.aiSettings.prompts.options[selectedTab].find(p => p._id === promptId);
        const newPrompt = editedPrompts[promptId] || currentPrompt.prompt;
        const newName = editedName[promptId] || currentPrompt.name;
        await additionalInfo.updatePrompt(selectedTab, promptId, newPrompt, newName);
    };

    const handleDeletePrompt = (promptId) => {
        setPromptToDelete(promptId);
        setOpenConfirmDialog(true);
    };

    const confirmDeletePrompt = async () => {
        if (promptToDelete) {
            await additionalInfo.deletePrompt(selectedTab, promptToDelete);
            setSelectedPromptId_forListItem(additionalInfo.aiSettings.prompts.selected[selectedTab]);
            localStorage.removeItem(`${LOCAL_STORAGE_KEY_FOR_PROMPT}_${selectedTab}`);
        }
        setOpenConfirmDialog(false);
        setPromptToDelete(null);
    };

    const handleCreatePrompt = async () => {
        await additionalInfo.createPrompt(selectedTab, "New Prompt", "שם הפרומפט");
    };

    return (
        <div>
            {/* Tabs */}
            <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                sx={{ minHeight: "37px", height: "37px" }} // מקטין את גובה ה-Tabs
            >
                <Tab label="משפט" value="sentence" sx={{ minHeight: "32px", height: "32px", padding: "4px 8px" }} />
                <Tab label="הגדרה" value="definition" sx={{ minHeight: "32px", height: "32px", padding: "4px 8px" }} />
                <Tab label="תיאור תמונה" value="imageDescription" sx={{ minHeight: "32px", height: "32px", padding: "4px 8px" }} />
                <Tab label="תמונה" value="image" sx={{ minHeight: "32px", height: "32px", padding: "4px 8px" }} />
                <Tab label="סיפור" value="story" sx={{ minHeight: "32px", height: "32px", padding: "4px 8px" }} />
            </Tabs>

            {/* Toolbar */}
            <Box sx={{ position: "absolute", top: -10, right: 155, display: "flex", alignItems: "center", gap: 2 }}>
                <LoadingIndicator isLoading={additionalInfo.loading.aiSettings} />
                <InfoTooltip />
                <Button variant="contained" color="success" onClick={handleCreatePrompt} sx={{ mt: 2 }}>
                    יצירת פרומפט חדש
                </Button>
            </Box>

            {/* Prompts */}
            <Box sx={{ display: "flex", maxHeight: height ? `${height - 60}px` : "500px", minHeight: height ? `${height - 60}px` : "500px", mt: 0.5 }}>
                {/* רשימת שמות הפרומפטים (צד ימין) */}
                <Box
                    sx={{
                        width: "20%",
                        // borderRight: "2px solid #1976d2", 
                        overflowY: "auto", direction: "rtl", p: 0
                    }}
                >
                    <List sx={{ padding: 0 }}>
                        {additionalInfo.aiSettings.prompts.options[selectedTab].map((prompt) => (
                            <ListItemButton
                                key={prompt._id}
                                selected={selectedPromptId_forListItem === prompt._id}
                                onClick={() => handleListItemClick(prompt._id)}
                                sx={{
                                    padding: 0,
                                    pl: 0,
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    color: prompt.default ? "gray" : "black",
                                    borderRadius: "4px",
                                    border: "1px solid white",
                                    backgroundColor: "#F0F0F0",
                                    "&.Mui-selected": {
                                        backgroundColor: "#e3f2fd !important",
                                        border: "1px solid #1976d2",
                                    },
                                }}
                            >
                                <ListItemText primary={prompt.name} />
                                <Button
                                    variant="contained"
                                    color={additionalInfo.aiSettings.prompts.selected[selectedTab] === prompt._id ? "warning" : "primary"}
                                    onClick={async (e) => {
                                        e.stopPropagation(); // מונע שינוי התצוגה
                                        await additionalInfo.setSelectedPrompt(selectedTab, prompt._id);
                                    }}
                                    size="small"
                                    sx={{ minWidth: "60px", marginRight: "8px", marginLeft: "0px" }}
                                >
                                    {additionalInfo.aiSettings.prompts.selected[selectedTab] === prompt._id ? "נבחר" : "בחירה"}
                                </Button>
                            </ListItemButton>
                        ))}
                    </List>
                </Box>

                {/* הצגת הפרומפט הנבחר (צד שמאל) */}
                <Box sx={{ width: "80%", pl: 1.5, pb: 0.5, overflowY: "auto" }}>
                    {selectedPromptId_forListItem && (
                        (() => {
                            const prompt = additionalInfo.aiSettings.prompts.options[selectedTab].find(p => p._id === selectedPromptId_forListItem);
                            if (!prompt) return null;

                            const isDefault = prompt.default;
                            const isEmpty = editedPrompts[prompt._id] === "";

                            return (
                                <Grid
                                    container
                                    direction="column"
                                    alignItems="center"
                                    justifyContent="center"
                                    spacing={1}
                                    sx={{
                                        my: 0,
                                        width: "100%",
                                        px: 1,
                                        py: 0,
                                        border: "1px solid #1976d2 !important",
                                        borderRadius: "8px",
                                        backgroundColor: "#e3f2fd",
                                    }}
                                >
                                    {/* כפתורים ושם הפרומפט */}
                                    <Grid item sx={{ display: "flex", gap: 1, justifyContent: "center" }}>
                                        <TextField
                                            size="small"
                                            label="שם הפרומפט"
                                            disabled={isDefault}
                                            value={editedName[prompt._id] !== undefined ? editedName[prompt._id] : prompt.name}
                                            onChange={(e) => handleNameChange(prompt._id, e.target.value)}
                                            sx={{
                                                width: "100%",
                                                direction: /^[֐-׿]/.test(editedName[prompt._id] || prompt.name) ? "ltr" : "rtl",
                                            }}
                                        />
                                        {!isDefault && (
                                            <>
                                                <Button variant="contained" color="success" onClick={() => handleUpdatePrompt(prompt._id)} disabled={isEmpty}>
                                                    עדכון
                                                </Button>
                                                <Button variant="contained" color="error" onClick={() => handleDeletePrompt(prompt._id)}>
                                                    מחיקה
                                                </Button>
                                            </>
                                        )}
                                    </Grid>

                                    {/* שדה קלט */}
                                    <Grid container spacing={0} sx={{ mt: 0.8 }}>
                                        <TextField
                                            label="פרומפט"
                                            disabled={isDefault}
                                            fullWidth
                                            multiline
                                            value={editedPrompts[prompt._id] !== undefined ? editedPrompts[prompt._id] : prompt.prompt}
                                            onChange={(e) => handleInputChange(prompt._id, e.target.value)}
                                            sx={{
                                                width: "100%",
                                                direction: /^[֐-׿]/.test(editedPrompts[prompt._id] || prompt.prompt) ? "ltr" : "rtl",
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                    WebkitTextFillColor: "gray",
                                                },
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "black !important",
                                                },
                                                backgroundColor: "white",
                                            }}
                                        />
                                    </Grid>

                                    {/* הצגת הטקסט המעובד */}
                                    <Grid item sx={{ width: "100%", textAlign: "center" }}>
                                        <Typography variant="body2" sx={{ color: "text.secondary", marginBottom: 0, marginTop: -0.5 }}>
                                            {currentWord ? (
                                                <>
                                                    👇&nbsp;&nbsp;כך יראה הפרומפט עבור הערך:&nbsp;
                                                    {currentWord.original}, {currentWord.trns}, {currentWord.pos}&nbsp;&nbsp;👇
                                                </>
                                            ) : (
                                                "בחר ערך מהמילון כדי לראות איך הפרומפט יראה"
                                            )}
                                        </Typography>

                                        <Box
                                            sx={{
                                                width: "100%",
                                                direction: /^[֐-׿]/.test(editedPrompts[prompt._id] || prompt.prompt) ? "ltr" : "rtl",
                                                textAlign: /^[֐-׿]/.test(editedPrompts[prompt._id] || prompt.prompt) ? "left" : "right",
                                            }}
                                        >
                                            <Typography
                                                variant="body1"
                                                sx={{
                                                    width: "100%",
                                                    whiteSpace: "pre-wrap",
                                                    border: "1px solid #e3f2fd",
                                                    borderRadius: "4px",
                                                    padding: "8px",
                                                    backgroundColor: "#e3f2fd",
                                                    paddingTop: 0.1,
                                                }}
                                            >
                                                {
                                                    processedTexts[prompt._id] ||
                                                    replacePlaceholders(prompt.prompt, currentWord)
                                                }
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            );
                        })()
                    )}
                </Box>
            </Box>

            {/* דיאלוג אישור מחיקה */}
            <Dialog open={openConfirmDialog} onClose={() => setOpenConfirmDialog(false)}>
                <DialogContent>
                    <Typography>האם אתה בטוח שברצונך למחוק את הפרומפט?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenConfirmDialog(false)} color="primary">
                        ביטול
                    </Button>
                    <Button onClick={confirmDeletePrompt} color="error" variant="contained">
                        מחיקה
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
});

export default Prompt;
