import React, { useState, useEffect } from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography, Grid, Box, TextField, IconButton, CircularProgress } from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import CheckIcon from "@mui/icons-material/Check";

import { observer } from "mobx-react-lite";
import additionalInfo from "../../../Mobx/AdditionalInfo";

const ExistsStorySection = observer(() => {
    const existsInfo = additionalInfo.existsInfo;
    const expanded = additionalInfo.expandedState;
    const loading = additionalInfo.loading;

    const [editedSentences, setEditedSentences] = useState({});

    useEffect(() => {
        if (existsInfo && existsInfo.stories) {
            setEditedSentences(
                existsInfo.stories.reduce((acc, story) => {
                    acc[story._id] = story.text;
                    return acc;
                }, {})
            );
        }
    }, [existsInfo]);

    const handleToggle = () => {
        additionalInfo.setExpandedState({ ...expanded, stories: !expanded.stories });
    };

    return (
        <Grid item xs={12}>
            <Accordion
                expanded={expanded.stories}
                onChange={() => handleToggle("stories")}
                sx={{ boxShadow: 3, borderRadius: 2, width: "100%" }}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">סיפורים ({existsInfo.stories?.length || 0})</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box sx={{ width: "100%", textAlign: "center" }}>
                        {existsInfo.stories?.length > 0 ? (
                            existsInfo.stories.map((story, index) => (
                                <Box key={story._id} sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", bgcolor: "#f0f0f0", p: 1, pt: 0, borderRadius: 2, mt: 0.5 }}>
                                    <Box sx={{ display: "flex", gap: 0, ml: 1 }}>
                                        {story.default ?
                                            <Typography
                                                variant="body2"
                                                color="primary"
                                                sx={{ fontWeight: "bold", mt: 1.3, mr: 1 }}
                                            >
                                                ברירת מחדל
                                            </Typography>
                                            :
                                            <IconButton
                                                onClick={() => additionalInfo.updateExistsInfo(existsInfo._id, "stories", story._id, { default: true })}
                                                disabled={loading.updateExistsInfo.stories?.[story._id] === "default"}
                                            >
                                                {loading.updateExistsInfo.stories?.[story._id] === "default" ? <CircularProgress size={24} /> : <CheckIcon color="primary" />}
                                            </IconButton>
                                        }
                                        <IconButton
                                            onClick={() => additionalInfo.updateExistsInfo(existsInfo._id, "stories", story._id, { text: editedSentences[story._id] })}
                                            disabled={loading.updateExistsInfo.stories?.[story._id] === "text"}
                                        >
                                            {editedSentences[story._id] && (loading.updateExistsInfo.stories?.[story._id] === "text" ? <CircularProgress size={24} /> : <SaveIcon color="primary" />)}
                                        </IconButton>
                                        <IconButton
                                            onClick={() => additionalInfo.deleteExistsInfo(existsInfo._id, "stories", story._id)}
                                            disabled={loading.deleteExistsInfo.stories?.[story._id]}
                                        >
                                            {loading.deleteExistsInfo.stories?.[story._id] ? <CircularProgress size={24} /> : <DeleteIcon color="error" />}
                                        </IconButton>
                                    </Box>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        multiline
                                        minRows={1}
                                        value={editedSentences[story._id]}
                                        onChange={(e) =>
                                            setEditedSentences({
                                                ...editedSentences,
                                                [story._id]: e.target.value,
                                            })
                                        }
                                        sx={{ direction: /^[\u0590-\u05FF]/.test(editedSentences[story._id] || "") ? "ltr" : "rtl" }}
                                    />
                                </Box>
                            ))
                        ) : (
                            <Typography variant="body2" color="textSecondary">אין סיפורים זמינים</Typography>
                        )}
                    </Box>
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
});

export default ExistsStorySection;
